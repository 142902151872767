import { types } from './carInfomation.type';

const initState = {
  brands: [],
  isLoading: false,
  isError: false,
};

export const carInfomationReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GETTING_CAR_BRANDS_MODELS_TASCO:
      return {
        ...state,
        brands: [],
        isLoading: true,
        isError: false
      };
    case types.GETTING_CAR_MIC_BRANDS:
      return {
        ...state,
        brands: [],
        isLoading: true,
        isError: false
      };
    case types.GETTING_CAR_MIC_MODELS:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case types.GET_CAR_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.brands,
        isLoading: false,
        isError: false
      };
    case types.GET_CAR_MODELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false
      };
    case types.GET_CAR_BRANDS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case types.GET_CAR_MODELS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case types.CLEAR_CAR_BRANDS:
      return {
        ...state,
        brands: [],
        isLoading: false,
        isError: false
      }
    default:
      return state;
  }
};
