const getImagePath = (path) => {
    return `${process.env.PUBLIC_URL}/${path}`;
};
const images = {
    zalopay_banner_uat: getImagePath('images/baohiemtainan-banner.jpg'),
    zalopay_banner_prod: getImagePath('images/zalo-banner.jpg'),
    zalopay_banner_car: getImagePath('images/banner-voucher-car.jpg'),
    zalopay_banner_car_v2: getImagePath('images/banner-oto-v3.jpg'),
    zalopay_banner_xm: getImagePath('images/banner-xm.jpg'),
    zalopay_banner_xm_v2: getImagePath('images/banner-xm-v4.jpg'),
    zalopay_notify: getImagePath('images/zalopay-notify.svg'),
    logo_zalopay: getImagePath('images/logo-zalopay.svg'),
    icon_clear_text: getImagePath('images/close-text.svg'),
    icon_close: getImagePath('images/clear.svg'),
    searchNullIcon: getImagePath('images/search-null.svg'),
    icon_calendar: getImagePath('images/calendar.svg'),
    icon_checkbox_active: getImagePath('images/checkbox-active.svg'),
    icon_checkbox_inactive: getImagePath('images/checkbox-inactive.svg'),

    icon_arrow_top: getImagePath('images/arrow-top.svg'),
    icon_home_active: getImagePath('images/home-active.svg'),
    icon_home_inactive: getImagePath('images/home-inactive.svg'),
    icon_contract_active: getImagePath('images/contract-active.svg'),
    icon_contract_inactive: getImagePath('images/contract-inactive.svg'),
    icon_accident: getImagePath('images/icon-accident.svg'),
    icon_bike: getImagePath('images/icon-bike.svg'),
    icon_car: getImagePath('images/icon-car.svg'),
    icon_home: getImagePath('images/icon-home.svg'),
    icon_mdi_villa: getImagePath('images/icon-mdi-villa.svg'),
    icon_mdi_apartment: getImagePath('images/icon-mdi-apartment.svg'),
    icon_mdi_home: getImagePath('images/icon-mdi-home.svg'),
    icon_mdi_townhouse: getImagePath('images/icon-mdi-townhouse.svg'),
    icon_mdi_car_convertible: getImagePath('images/icon-mdi-car-convertible.svg'),
    icon_mdi_car_estate: getImagePath('images/icon-mdi-car-estate.svg'),
    icon_mdi_truck: getImagePath('images/icon-mdi-truck.svg'),
    banner_accident: getImagePath('images/banner-accident.svg'),
    banner_bike: getImagePath('images/banner-bike.svg'),
    pti: getImagePath('images/pti.svg'),
    radio_active: getImagePath('images/radio-active.svg'),
    arrow_right: getImagePath('images/arrow-right.svg'),
    info: getImagePath('images/info.svg'),
    auto_renew_inactive: getImagePath('images/auto-renew-inactive.svg'),
    auto_renew_active: getImagePath('images/auto-renew-active.svg'),
    icon_search: getImagePath('images/search-gray.svg'),
    icon_systems_other_arrow_right: getImagePath('images/icon-systems-other-arrow-right.svg'),
    icon_systems_outline_filter: getImagePath('images/icon-systems-outline-filter.svg'),
    icon_systems_other_check_done: getImagePath('images/icon-systems-other-check-done.svg'),
    icon_systems_glyph_info_tutorial: getImagePath('images/icon-systems-glyph-info-tutorial.svg'),
    icon_systems_glyph_info: getImagePath('images/icon-systems-glyph-info.svg'),
    icon_systems_outline_filter_active: getImagePath('images/icon-systems-outline-filter-active.svg'),
    icon_merchant_save_money_full: getImagePath('images/icon-merchant-save-money-full.svg'),
    icon_systems_glyph_new_feature: getImagePath('images/icon-systems-glyph-new-feature.svg'),
    icon_systems_outline_auto_renew: getImagePath('images/icon-systems-outline-auto-renew.svg'),
    logo_zalopay_rectangle: getImagePath('images/logo-zalopay-rectangle.svg'),
    cancel_autodebit: getImagePath('images/cancel-autodebit-2.svg'),
    icon_contact: getImagePath('images/icon-contact.svg'),
    icon_share_link: getImagePath('images/icon-share-link.svg'),
    icon_copy: getImagePath('images/icon-copy.png'),
    icon_check: getImagePath('images/icon-check.png'),
    icon_search: getImagePath('images/icon-search.svg'),
    icon_search_active: getImagePath('images/icon-search-active.svg'),
    background_captcha: getImagePath('images/background-captcha.jpg'),
    icon_refresh: getImagePath('images/icon-refresh.svg')
}

export default images
