import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import Util from "../../../utils/utils";
import FloatInput from "../../../components/FloatInput/FloatInput";
import DatePicker from "../../../components/DatePicker";
import moment from "moment";
import { updateCustomerInfo } from "../../../stores/customer/customer.action";
import ChooseAddress from "./ChooseAddress";
import { scrollToRefObject } from "../../../utils/helper";
import { ABIC_PRODUCT_CODES, BSH_PRODUCT_CODES, UIC_PRODUCT_CODES } from "utils/const";

const ContactInput = forwardRef((props, ref) => {
  // const minDate = props.minDateEffect || 2;
  const { minAge = 0, maxAge = 0, productCode, onBlurPhone } = props;
  const minDate = ABIC_PRODUCT_CODES.includes[productCode] ? props.minDateEffect : props.insuranceDetail.type === "car" ? props.minDateEffect : props.minDateEffect || 2;

  const dispatch = useDispatch();
  const { customerInfo } = useSelector((state) => state.customer);
  const { userInfo } = useSelector((state) => state.appParams);

  const [fullName, setFullName] = useState(userInfo && userInfo.user_name ? userInfo.user_name.toUpperCase() : "");
  const [phone, setPhone] = useState(
    userInfo && userInfo.user_phone ? userInfo.user_phone : ""
  );
  // const [identity, setIdentity] = useState(
  //   customerInfo && customerInfo.identity ? customerInfo.identity : ""
  // );
  const [identity, setIdentity] = useState(
    userInfo && userInfo.id_number ? userInfo.id_number : ""
  );
  const [dobEffect, setDobEffect] = useState(
    moment().subtract(minAge, "years").format("DD/MM/YYYY")
  );
  const [email, setEmail] = useState(
    userInfo && userInfo.user_email ? userInfo.user_email : ""
  );
  const [dateEffect, setDateEffect] = useState(
    moment().add(minDate, "days").format("DD/MM/YYYY HH:mm")
  );
  const [address, setAddress] = useState(
    customerInfo && customerInfo.address ? customerInfo.address : ""
  );
  const [city, setCity] = useState(
    customerInfo && customerInfo.city ? customerInfo.city : {}
  );
  const [district, setDistrict] = useState(
    customerInfo && customerInfo.district ? customerInfo.district : {}
  );
  const [ward, setWard] = useState(
    customerInfo && customerInfo.ward ? customerInfo.ward : {}
  );
  const [refChooseAddress, setRefChooseAddress] = useState(null);

  const [isValidFullName, setIsValidFullName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidIdentity, setIsValidIdentity] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidAddress, setIsValidAddress] = useState(true);
  const [isValidCity, setIsValidCity] = useState(true);
  const [isValidDistrict, setIsValidDistrict] = useState(true);
  const [isValidWard, setIsValidWard] = useState(true);

  const refFullName = useRef();
  const refPhone = useRef();
  const refIdentity = useRef();
  const refEmail = useRef();
  const refAddress = useRef();

  const refScrollFullName = useRef();
  const refScrollDateEffect = useRef();
  const refScrollPhone = useRef();
  const refScrollIdentity = useRef();
  const refScrollDobEffect = useRef();
  const refScrollEmail = useRef();
  const refScrollChooseAddress = useRef();
  const refScrollAddress = useRef();

  useEffect(() => {
    let contact = getCustomerInfo();
    dispatch(updateCustomerInfo(contact));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName, phone, identity, dobEffect, email, dateEffect, address, city, district, ward]);

  const onNameChange = (value) => {
    // const nameValue = Util.toTitleCase(value);
    // const valid = Util.validateName(nameValue);
    // setIsValidFullName(valid);
    // const name = [...ABIC_PRODUCT_CODES, ...BSH_PRODUCT_CODES].includes(productCode) ? nameValue.toUpperCase() : nameValue;
    // setFullName(name);
    const valid = Util.validateName(value);
    setIsValidFullName(valid);
    setFullName(value);
  };

  const onPhoneChange = (value) => {
    let valid = Util.validatePhone(value);
    setIsValidPhone(valid);
    setPhone(value);
  };

  const onPhoneEndEditing = () => {
    if(phone && isValidPhone) {
      onBlurPhone()
    }
  }

  const onIdentityChange = (value) => {
    let valid = Util.validateIdentity(value);
    setIsValidIdentity(valid);
    setIdentity(value);
  };

  const onDobEffectChange = (value) => {
    let newDate = moment(value, "DD/MM/YYYY").format("DD/MM/YYYY");
    setDobEffect(newDate);
  };

  const onEmailChange = (value) => {
    const validateEmail = ABIC_PRODUCT_CODES.includes(productCode) ? Util.validateEmailNoCheck(value) : Util.validateEmail(value);
    let valid = validateEmail || !value || value.trim() === "";
    setIsValidEmail(valid);
    setEmail(value);
  };

  const onDateEffectChange = (value) => {
    let newDate = moment(value, "DD/MM/YYYY").format("DD/MM/YYYY");
    setDateEffect(newDate);
  };

  const onAddressChange = (value) => {
    let valid = Util.validateAddress(value);
    setIsValidAddress(valid);
    setAddress(value);
  };

  const onChooseAddressChange = (value, refChooseAddress) => {
    setRefChooseAddress(refChooseAddress);
    setIsValidCity(!!value.city);
    setCity(value.city ? value.city : "");

    setIsValidDistrict(!!value.district);
    setDistrict(value.district ? value.district : "");

    setIsValidWard(!!value.ward);
    setWard(value.ward ? value.ward : "");
  };

  const getCustomerInfo = () => {
    return {
      email,
      fullName,
      phone,
      identity,
      dobEffect,
      dateEffect,
      address,
      city,
      district,
      ward,
    };
  };

  useImperativeHandle(ref, () => ({
    validateInfo() {
      let validFullName = Util.validateName(fullName);
      setIsValidFullName(validFullName);
      let validPhone = Util.validatePhone(phone);
      setIsValidPhone(validPhone);
      let validIdentity = UIC_PRODUCT_CODES.includes(productCode) ? Util.validateIdentity(identity) : true;
      setIsValidIdentity(validIdentity);
      // let validEmail = ABIC_PRODUCT_CODES.includes(productCode) ? Util.validateEmailNoCheck(email) : Util.validateEmail(email);
      let validEmail = Util.validateEmail(email);
      setIsValidEmail(validEmail);
      let validAddress = Util.validateAddress(address);
      setIsValidAddress(validAddress);
      let validCity = Util.validateEmptyText(city.code);
      setIsValidCity(validCity);
      let validDistrict = Util.validateEmptyText(district.code);
      setIsValidDistrict(validDistrict);
      let validWard = Util.validateEmptyText(ward.code);
      setIsValidWard(validWard);

      let valid =
        validFullName &&
        validPhone &&
        validIdentity &&
        validEmail &&
        validAddress &&
        validCity &&
        validDistrict &&
        validWard;

      if (!validFullName) {
        scrollToRefObject(refScrollFullName);
        return valid;
      }
      if (!validPhone) {
        scrollToRefObject(refScrollPhone);
        return valid;
      }
      if (!validIdentity) {
        scrollToRefObject(refScrollIdentity);
        return valid;
      }
      if (!validEmail) {
        scrollToRefObject(refScrollEmail);
        return valid;
      }
      if (!validWard) {
        scrollToRefObject(refScrollChooseAddress);
        return valid;
      }
      if (!validAddress) {
        scrollToRefObject(refScrollAddress);
        return valid;
      }
      return valid;
    },
    validateInfoByPhone() {
      let validFullName = Util.validateName(fullName);
      setIsValidFullName(validFullName);
      let validPhone = Util.validatePhone(phone);
      setIsValidPhone(validPhone);
      let validIdentity = UIC_PRODUCT_CODES.includes(productCode) ? Util.validateIdentity(identity) : true;
      setIsValidIdentity(validIdentity);
      // let validEmail = ABIC_PRODUCT_CODES.includes(productCode) ? Util.validateEmailNoCheck(email) : Util.validateEmail(email);
      let validEmail = Util.validateEmail(email);
      setIsValidEmail(validEmail);
      let validAddress = Util.validateAddress(address);
      setIsValidAddress(validAddress);
      let validCity = Util.validateEmptyText(city.code);
      setIsValidCity(validCity);
      let validDistrict = Util.validateEmptyText(district.code);
      setIsValidDistrict(validDistrict);
      let validWard = Util.validateEmptyText(ward.code);
      setIsValidWard(validWard);

      let valid =
        validFullName &&
        validPhone &&
        validIdentity &&
        validEmail &&
        validAddress &&
        validCity &&
        validDistrict &&
        validWard;
      
      return valid;
    },
    getCustomerInfo() {
      return getCustomerInfo();
    },
  }));

  const datePickerValue = moment(dateEffect, "DD/MM/YYYY HH:mm");
  const dobDatePickerValue = moment(dobEffect, "DD/MM/YYYY");

  return (
    <div>
      <div className="form_title">
        <div>Thông tin người mua bảo hiểm</div>
      </div>
      <div className="form_row">
        <div ref={refScrollFullName} className="w-100">
          <FloatInput
            ref={refFullName}
            id="refFullName"
            label={isValidFullName ? "Họ và Tên" : Util.invalidInfo("fullName")}
            value={fullName}
            iconClear={true}
            textTransform={"uppercase"}
            error={!isValidFullName}
            onSubmitEditing={() => {
              refScrollPhone.current.focus();
            }}
            onChangeText={onNameChange}
            require
          />
        </div>

        <div ref={refScrollPhone} className="w-100">
          <FloatInput
            ref={refPhone}
            id="refPhone"
            type="tel"
            label={isValidPhone ? "Số điện thoại" : Util.invalidInfo("phone")}
            value={phone}
            iconClear={true}
            error={!isValidPhone}
            onSubmitEditing={() => {
              if (UIC_PRODUCT_CODES.includes(productCode)) {
                refIdentity.current.focus();
              } else {
                refEmail.current.focus();
              }
            }}
            onChangeText={onPhoneChange}
            onEndEditing={onPhoneEndEditing}
            require
          />
        </div>

        {
          UIC_PRODUCT_CODES.includes(productCode) && (
            <>
              <div ref={refScrollIdentity} className="w-100">
                <FloatInput
                  ref={refIdentity}
                  id="refIdentity"
                  type="tel"
                  label={isValidIdentity ? "CMND / CCCD" : Util.invalidInfo("identity")}
                  value={identity}
                  iconClear={true}
                  error={!isValidIdentity}
                  onChangeText={onIdentityChange}
                />
              </div>

              <div className="w-100 margin-bottom-16" ref={refScrollDobEffect}>
                <DatePicker
                  error={false}
                  title="Ngày sinh"
                  placeholder="Ngày sinh"
                  minDate={moment().startOf("date").subtract(maxAge, "years").toDate()}
                  maxDate={moment().startOf("date").subtract(minAge, "years").toDate()}
                  date={dobDatePickerValue.isValid() ? dobDatePickerValue.toDate() : null}
                  defaultDate={moment().subtract(minAge, "years").toDate()}
                  onChange={onDobEffectChange}
                />
              </div>
            </>
          )
        }

        <div ref={refScrollEmail} className="w-100">
          <FloatInput
            ref={refEmail}
            id="refEmail"
            label={isValidEmail ? "Email để nhận giấy bảo hiểm" : Util.invalidInfo("email")}
            value={email}
            iconClear={true}
            error={!isValidEmail}
            onChangeText={onEmailChange}
            // require={ABIC_PRODUCT_CODES.includes(productCode) ? false : true}
            require={true}
          />
        </div>

        <div className="w-100 margin-bottom-16" ref={refScrollDateEffect}>
          <DatePicker
            error={false}
            title="Ngày hiệu lực"
            placeholder="Ngày hiệu lực"
            minDate={moment().add(minDate, "days").startOf("date").toDate()}
            maxDate={moment().startOf("date").add(365 + minDate, "days").toDate()}
            date={datePickerValue.isValid() ? datePickerValue.toDate() : null}
            defaultDate={moment().add(minDate, "days").toDate()}
            onChange={onDateEffectChange}
            require
          />
        </div>

        <>
          <div ref={refScrollChooseAddress} className="w-100">
            <ChooseAddress
              data={{ city, district, ward }}
              valid={{ isValidCity, isValidDistrict, isValidWard }}
              onchange={onChooseAddressChange}
              refInput={refChooseAddress}
            />
          </div>

          <div ref={refScrollAddress} className="w-100">
            <FloatInput
              ref={refAddress}
              label={isValidAddress ? "Địa chỉ (số nhà + tên đường)" : Util.invalidInfo("address")}
              value={address}
              iconClear={true}
              error={!isValidAddress}
              onChangeText={onAddressChange}
              require
            />
          </div>
        </>
      </div>
    </div>
  );
});

export default React.memo(ContactInput);
