import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './stores/configStore';
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Provider } from 'react-redux';
import ApolloProvider from './graphql/ApolloProvider';

import { APPID, APPID_CAR } from './utils/const';
import ZaloPaySDK from './utils/zalopay';

const Root = () => {
    return (
        <Provider store={store}>
            <ApolloProvider>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </ApolloProvider>
        </Provider>
    )
}

// const queryString = window.location.search;
// const urlParams = new URLSearchParams(queryString);
// const productType = urlParams.get('product_type');

// const appID = productType ? ["motobike", "accident"].includes(productType) ? APPID : APPID_CAR : APPID

ZaloPaySDK.getOauthV1Code(APPID, function () {
    ReactDOM.render(<Root />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
