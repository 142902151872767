import { AppApiInstance } from "api/app-api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "screens/HomeScreen/HomeScreen";
import ZaloPaySDK from "utils/zalopay";

const InsuranceInfo = (props) => {
  const { typeContract, contract, quotationCode, mUid } = props;
  const [isLoading, setIsLoading] = useState(false);
  const queryMUID = useQuery().get('muid');

  const handleClickViewGCN = async () => {
    setIsLoading(true);
    try {
      const { data: { getPolicyByQuotationCode } } = await AppApiInstance.getContractDetail({  
        quotationCode: quotationCode,
        mUid: queryMUID ? queryMUID : mUid || ''
      });
      const urlGCN = getPolicyByQuotationCode.insuredMemberDetail[0].certificate_link;
      console.log(urlGCN)
      handleViewGCN(urlGCN);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleViewGCN = (urlGCN) => {
    if(urlGCN) {
      if(typeContract === "contract-detail") {
        ZaloPaySDK.openExternalBrowser(urlGCN);
      }else if(typeContract === "external-contract-detail") {
        let policyNumber = urlGCN.split("?")[1].split("&")[0].split("=")[1];
        const replaceUrlGCN = urlGCN.replace(policyNumber, encodeURIComponent(policyNumber));
        console.log(replaceUrlGCN);
        ZaloPaySDK.openExternalBrowser(replaceUrlGCN);
      }
    }else {
      ZaloPaySDK.showToast({
        mess: "Giấy chứng nhận đang cập nhật! Bạn vui lòng thử lại lần nữa.",
        position: "center"
      })
    }
  }

  useEffect(() => {
    if(isLoading) {
      ZaloPaySDK.showLoading();
    }else {
      ZaloPaySDK.hideLoading();
    }
  }, [isLoading])

  return (
    <div className="bg-white padding-top-16 margin-bottom-16">
      <div className="margin-h-16 font-size-16 font-weight-bold margin-bottom-8 color-black">
        Thông tin nhận GCNBH
      </div>
      <div className="row">
        <div className="row-left">Giấy chứng nhận bảo hiểm</div>
        <div className="row-right color-blue" onClick={() => handleClickViewGCN()}>
          <p style={{margin: 0}}>Xem giấy chứng nhận</p>
        </div>
      </div>
      <div className="row">
        <div className="row-left">Họ và tên</div>
        <div className="row-right">{contract.holderName}</div>
      </div>

      <div className="row">
        <div className="row-left">Số điện thoại</div>
        <div className="row-right">{contract.holderPhoneNumber}</div>
      </div>
      {/* <div className="row">
        <div className="row-left">Địa chỉ</div>
        <div className="row-right">
          {contract.recipient.address}, {contract.recipient.wardNameWithType},{" "}
          {contract.recipient.districtNameWithType}, {contract.recipient.cityProvinceNameWithType}
        </div>
      </div> */}
    </div>
  );
};

export default React.memo(InsuranceInfo);
