import React, {
    useState,
    useImperativeHandle,
    forwardRef,
    useRef,
  } from "react";
  import { useLocation } from 'react-router-dom';
  import Util from "../../../utils/utils";
  import FloatInput from "../../../components/FloatInput/FloatInput";
  import { Checkbox } from "../../../components/Checkbox";
  import ChooseAddress from "./ChooseAddress";
  import { scrollToRefObject } from "../../../utils/helper";
  import { ABIC_PRODUCT_CODES, HDI_PRODUCT_CODES } from "utils/const";
  
  const CarInputRecipient = forwardRef((props, ref) => {
    const { insuranceDetail: { productCode } } = useLocation().state;
  
    const [fullNameRecipient, setFullNameRecipient] = useState("");
    const [phoneRecipient, setPhoneRecipient] = useState("");
    const [addressRecipient, setAddressRecipient] = useState("");
    const [cityRecipient, setCityRecipient] = useState({});
    const [districtRecipient, setDistrictRecipient] = useState({});
    const [wardRecipient, setWardRecipient] = useState({});
    const [isChecked, setIsChecked] = useState(true);
    const [refChooseAddress, setRefChooseAddress] = useState(null);
  
    const [isValidFullNameRecipient, setIsValidFullNameRecipient] = useState(
      true
    );
    const [isValidPhoneRecipient, setIsValidPhoneRecipient] = useState(true);
    const [isValidAddressRecipient, setIsValidAddressRecipient] = useState(true);
    const [isValidCityRecipient, setIsValidCityRecipient] = useState(true);
    const [isValidDistrictRecipient, setIsValidDistrictRecipient] = useState(
      true
    );
    const [isValidWardRecipient, setIsValidWardRecipient] = useState(true);
  
    const refPhoneRecipient = useRef();
    const refAddressRecipient = useRef();
    const refFullNameRecipient = useRef();
  
    const refScrollFullName = useRef();
    const refScrollPhone = useRef();
    const refScrollChooseAddress = useRef();
    const refScrollAddress = useRef();
  
    const onNameRecipientChange = (value) => {
      const nameValue = Util.toTitleCase(value);
      const valid = Util.validateName(nameValue);
      setIsValidFullNameRecipient(valid);
      setFullNameRecipient(nameValue);
    };
  
    const onPhoneRecipientChange = (value) => {
      let valid = Util.validatePhone(value);
      setIsValidPhoneRecipient(valid);
      setPhoneRecipient(value);
    };
  
    const onChooseAddressRecipientChange = (value, refChooseAddress) => {
      setRefChooseAddress(refChooseAddress);
      setIsValidCityRecipient(!!value.city);
      setCityRecipient(value.city ? value.city : "");
  
      setIsValidDistrictRecipient(!!value.district);
      setDistrictRecipient(value.district ? value.district : "");
  
      setIsValidWardRecipient(!!value.ward);
      setWardRecipient(value.ward ? value.ward : "");
    };
  
    const onAddressRecipientChange = (value) => {
      let valid = Util.validateAddress(value);
      setIsValidAddressRecipient(valid);
      setAddressRecipient(value);
    };
  
    const onClickCheckbox = (value) => {
      setIsChecked(value);
    };
  
    const getInsuranceRecipient = () => {
      return {
        fullNameRecipient,
        phoneRecipient,
        addressRecipient,
        cityRecipient,
        districtRecipient,
        wardRecipient,
      };
    };
  
    const validateInfo = (fullName, phone, city, district, ward, address) => {
      const validFullNameRecipient = Util.validateName(fullName);
      setIsValidFullNameRecipient(validFullNameRecipient);
      const validPhone = Util.validatePhone(phone);
      setIsValidPhoneRecipient(validPhone);
      const validCity = Util.validateEmptyText(city.code);
      setIsValidCityRecipient(validCity);
      const validDistrict = Util.validateEmptyText(district.code);
      setIsValidDistrictRecipient(validDistrict);
      const validWard = Util.validateEmptyText(ward.code);
      setIsValidWardRecipient(validWard);
      const validAddress = Util.validateAddress(address);
      setIsValidAddressRecipient(validAddress);
  
      const valid =
        validFullNameRecipient &&
        validPhone &&
        validCity &&
        validDistrict &&
        validWard &&
        validAddress;
  
      if (!validFullNameRecipient) {
        scrollToRefObject(refScrollFullName);
        return valid;
      }
      if (!validPhone) {
        scrollToRefObject(refScrollPhone);
        return valid;
      }
      if (!validWard) {
        scrollToRefObject(refScrollChooseAddress);
        return valid;
      }
      if (!validAddress) {
        scrollToRefObject(refScrollAddress);
        return valid;
      }
  
      return valid;
    };
  
    useImperativeHandle(ref, () => ({
      validateInfo() {
        if (isChecked) return true;
        return validateInfo(
          fullNameRecipient,
          phoneRecipient,
          cityRecipient,
          districtRecipient,
          wardRecipient,
          addressRecipient
        );
      },
      getInsuranceRecipient() {
        return getInsuranceRecipient();
      },
    }));
  
    if (HDI_PRODUCT_CODES.includes(productCode)) {
      return (
        <>
          <div className="form_row">
            <div className="w-100 font-size-12 color-gray margin-bottom-16">
              Lưu ý: Giấy chứng nhận bảo hiểm bắt buộc trách nhiệm dân sự và bảo hiểm tự nguyện chủ xe ô tô được cấp Giấy chứng nhận bảo hiểm điện tử qua email hoặc tin nhắn SMS.
            </div>
          </div>
        </>
      )
    }

    if (ABIC_PRODUCT_CODES.includes(productCode)) {
      return (
        <>
          <div className="form_row">
            <div className="w-100 font-size-12 color-gray margin-bottom-16">
            Lưu ý: Giấy chứng nhận bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới được ABIC cấp Giấy chứng nhận bảo hiểm điện tử qua email hoặc tin nhắn SMS (kèm link tra cứu)
            </div>
          </div>
        </>
      )
    }
  
    return (
      <>
        <div className="form_title">
          <div>Thông tin nhận giấy chứng nhận BH</div>
        </div>
        <div className="form_row">
          <div className="flex-v-center w-100 margin-bottom-16">
            <Checkbox
              style={{ marginRight: 12, alignSelf: "flex-start" }}
              onClick={onClickCheckbox}
              isChecked={isChecked}
            />
            <div className="certification-insurance">
              <div className="font-size-14">Dùng thông tin chủ xe để nhận</div>
              <div className="font-size-11 color-gray margin-top-4">
                (Bỏ chọn để nhập địa chỉ nơi nhận khác)
              </div>
            </div>
          </div>
          {!isChecked ? (
            <>
              <div ref={refScrollFullName} className="w-100">
                <FloatInput
                  ref={refFullNameRecipient}
                  label={
                    isValidFullNameRecipient
                      ? "Họ và Tên"
                      : Util.invalidInfo("fullName")
                  }
                  value={fullNameRecipient}
                  iconClear={true}
                  textTransform="capitalize"
                  error={!isValidFullNameRecipient}
                  onSubmitEditing={() => {
                    refPhoneRecipient.current.focus();
                  }}
                  onChangeText={onNameRecipientChange}
                />
              </div>
              <div ref={refScrollPhone} className="w-100">
                <FloatInput
                  ref={refPhoneRecipient}
                  type="tel"
                  label={
                    isValidPhoneRecipient
                      ? "Số điện thoại"
                      : Util.invalidInfo("phone")
                  }
                  value={phoneRecipient}
                  iconClear={true}
                  error={!isValidPhoneRecipient}
                  onChangeText={onPhoneRecipientChange}
                />
              </div>
              <div ref={refScrollChooseAddress} className="w-100">
                <ChooseAddress
                  data={{
                    city: cityRecipient,
                    district: districtRecipient,
                    ward: wardRecipient,
                  }}
                  valid={{
                    isValidCity: isValidCityRecipient,
                    isValidDistrict: isValidDistrictRecipient,
                    isValidWard: isValidWardRecipient,
                  }}
                  onchange={onChooseAddressRecipientChange}
                  refInput={refChooseAddress}
                />
              </div>
              <div ref={refScrollAddress} className="w-100">
                <FloatInput
                  ref={refAddressRecipient}
                  label={
                    isValidAddressRecipient
                      ? "Địa chỉ"
                      : Util.invalidInfo("address")
                  }
                  value={addressRecipient}
                  iconClear={true}
                  error={!isValidAddressRecipient}
                  onChangeText={onAddressRecipientChange}
                />
              </div>
            </>
          ) : null}
  
          <div className="w-100 font-size-12 color-gray margin-bottom-16">
            Lưu ý: Giấy chứng nhận bảo hiểm bắt buộc trách nhiệm dân sự và bảo
            hiểm tự nguyện của chủ xe ô tô sẽ được gửi đến địa chỉ trên
            trong vòng 3 ngày làm việc (không bao gồm thứ 7, chủ nhật, và các ngày
            lễ tết) kể từ lúc thanh toán thành công.
          </div>
        </div>
      </>
    );
  });
  
  export default React.memo(CarInputRecipient);
  