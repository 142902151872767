import React from "react";
import { Footer } from "../components/Footer/Footer";
import HomeScreen from "./HomeScreen/HomeScreen";
import ManagementScreen from "./ManagementScreen/ManagementScreen";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { PATH } from "../utils/const";
import { SafeAreaView } from "../components/SafeAreaView";
import SearchProductExternalScreen from "./SearchProductExternalScreen/SearchProductExternalScreen";

const MainScreen = () => {
  const {search} = useLocation();
  return (
    <SafeAreaView className="bg">
      <Switch>
        <Route exact path={`${PATH}`}>
          <Redirect to={{
            pathname: `${PATH}/home`,
            search,
          }} />
        </Route>
        <Route path={`${PATH}/home`} children={<HomeScreen />} />
        <Route path={`${PATH}/management`} children={<ManagementScreen />} />
        <Route path={`${PATH}/search`} children={<SearchProductExternalScreen />} />
      </Switch>
      <Footer />
    </SafeAreaView>
  );
};

export default MainScreen;
