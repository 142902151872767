import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import Util from "../../utils/utils";
import ZaloPaySDK from "../../utils/zalopay";
import Button from "../../components/Button";
import colors from "../../utils/colors";
import InsuranceInfo from "./components/InsuranceInfo";
import ContractOwnerInfo from "./components/ContractOwnerInfo";
import MotobikeInfo from "./components/MotobikeInfo";
import { ConfirmTerm } from "../../components/ConfirmTerm/ConfirmTerm";
import { SafeAreaView } from "../../components/SafeAreaView";
import { useDispatch, useSelector } from "react-redux";
import { payOrder } from "../../stores/payment/payment.action";
import { PATH } from "../../utils/const";
import { clearPaymentInfo } from "../../stores/payment/payment.action";
import moment from "moment";
import trackingId from "../../utils/tracking";
import { scrollToRefObject } from "../../utils/helper";
import { getContractDetail } from "../../stores/contract/contract.action";

const ContractReviewScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const refScrollAgreeTerm = useRef();
  const [agreeTerm, setAgreeTerm] = useState(false);
  const { isPayment } = useSelector((state) => state.payment);
  const { quotationCode } = useParams();

  const { userInfo } = useSelector((state) => state.appParams);
  const {
    contractDetail,
    gettingContractDetail,
    getContractDetailErrMsg,
  } = useSelector((state) => state.contract);
  const { param } = useSelector((state) => state.insurance);
  const { createPayOrder, paymentInfo, createPayOrderErrMsg } = useSelector(
    (state) => state.payment
  );

  useEffect(() => {
    dispatch(getContractDetail(quotationCode));
  }, [dispatch, quotationCode, userInfo.mUid]);

  useEffect(() => {
    if (gettingContractDetail) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [gettingContractDetail]);

  useEffect(() => {
    ZaloPaySDK.showDialog({
      message: getContractDetailErrMsg,
      buttons: [{ text: "Đóng" }],
    });
  }, [getContractDetailErrMsg]);

  useEffect(() => {
    ZaloPaySDK.setTitle("Thông tin hợp đồng");
    ZaloPaySDK.tracking(trackingId.REVIEW_CONTRACT.LOAD_PAGE_SUCCESSFULLY);
  }, []);

  useEffect(() => {
    if (paymentInfo && isPayment) {
      // history.block(true);
      ZaloPaySDK.payOrder(paymentInfo, cb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo, isPayment]);

  useEffect(() => {
    if (createPayOrder) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [createPayOrder]);

  useEffect(() => {
    if (createPayOrderErrMsg) {
      ZaloPaySDK.showDialog({
        message: createPayOrderErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
  }, [createPayOrderErrMsg]);

  const onPayment = () => {
    if (agreeTerm) {
      ZaloPaySDK.tracking(trackingId.REVIEW_CONTRACT.PAYMENT);
      dispatch(payOrder(contractDetail.id, contractDetail.productType));
    } else {
      scrollToRefObject(refScrollAgreeTerm);
    }
  };

  const cb = (data) => {
    console.log(data)
    // dispatch(clearPaymentInfo());
    // if (data.error === 1) {
    //   history.push({
    //     pathname: `${PATH}/contract-detail/${contractDetail.quotationCode}`,
    //     state: {
    //       fromPaymentPage: true,
    //     },
    //   });
    // } else {
    //   if (data.error !== 4) {
    //     if(param) {
    //       history.push(`${PATH}/home?product_type=${param}`);
    //     }else {
    //       history.push(`${PATH}/home`);
    //     }
    //     // history.push(`${PATH}/home`);
    //   }
    // }
  };

  const onAgreeTermClick = (value) => {
    ZaloPaySDK.tracking(trackingId.REVIEW_CONTRACT.TICK_ON_CHECKBOX);
    setAgreeTerm(value);
  };

  return (
    <SafeAreaView id="wrapper-scroll">
      {contractDetail && (
        <>
          <InsuranceInfo
            quotationCode={contractDetail.quotationCode}
            title="Thông tin chương trình bảo hiểm"
            image={contractDetail.productLogo}
            insuranceName={(contractDetail.productTranslations
              && contractDetail.productTranslations.length
              && contractDetail.productTranslations[0].name) || ''}
            effectiveDate={moment(
              contractDetail.insuredMemberDetail[0].effective_date
            ).format("DD/MM/YYYY")}
            expiryDate={moment(
              contractDetail.insuredMemberDetail[0].expired_date
            ).format("DD/MM/YYYY")}
            companyName={
              contractDetail.companyTranslations.find(
                (item) => item.langId === "vi"
              ).name
            }
          />
          <ContractOwnerInfo
            fullName={contractDetail.holderName}
            phone={contractDetail.holderPhoneNumber}
            email={contractDetail.holderEmail}
            address={contractDetail.holderAddress}
            ward={contractDetail.holderWardNameWithType}
            district={contractDetail.holderDistrictNameWithType}
            city={contractDetail.holderCityProvinceNameWithType}
          />
          {contractDetail.insuredMemberDetail[0].plateNumber ? (
            <MotobikeInfo
              carOwner={contractDetail.insuredMemberDetail[0].vehicleOwnerName}
              licensePlate={contractDetail.insuredMemberDetail[0].plateNumber}
            />
          ) : null}

          <div style={{ padding: "0px 16px 16px" }} ref={refScrollAgreeTerm}>
            <ConfirmTerm
              onCheckboxClick={onAgreeTermClick}
              agreeTerm={agreeTerm}
              content={contractDetail.zeroDepClaims}
            />
          </div>
          <div className="button-fixed-bottom">
            <div className="price">
              {Util.formatMoneyVND(contractDetail.premium * (1 - (contractDetail.discountRate || 0) / 100))}đ
            </div>

            <Button
              className="btn-main"
              onPress={onPayment}
              style={{ backgroundColor: colors.main }}
              disabled={!agreeTerm}
            >
              Thanh toán
            </Button>
          </div>
        </>
      )}
    </SafeAreaView>
  );
};

export default ContractReviewScreen;
