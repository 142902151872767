import { types } from "./contract.type";

const initState = {
  gettingContracts: false,
  contracts: null,
  getContractsErrMsg: "",
  gettingContractDetail: false,
  contractDetail: null,
  getContractDetailErrMsg: "",
  gettingInsuranceEmbed: false,
  insuranceEmbed: null,
  getInsuranceEmbedErrMsg: "",
};

export const contractReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GETTING_CONTRACT_LIST:
      return {
        ...state,
        gettingContracts: true,
        contracts: null,
        getContractsErrMsg: "",
      };
    case types.GET_CONTRACT_LIST_SUCCESS:
      return {
        ...state,
        gettingContracts: false,
        contracts: action.contracts,
        getContractsErrMsg: "",
      };
    case types.GET_CONTRACT_LIST_FAIL:
      return {
        ...state,
        gettingContracts: false,
        contracts: null,
        getContractsErrMsg: action.errMsg,
      };
      // Contract detail
    case types.GETTING_CONTRACT_DETAIL:
      return {
        ...state,
        gettingContractDetail: true,
        contractDetail: null,
        getContractDetailErrMsg: "",
      };
    case types.GET_CONTRACT_DETAIL_SUCCESS:
      return {
        ...state,
        gettingContractDetail: false,
        contractDetail: action.contractDetail,
        getContractDetailErrMsg: "",
      };
    case types.GET_CONTRACT_DETAIL_FAIL:
      return {
        ...state,
        gettingContractDetail: false,
        contractDetail: null,
        getContractDetailErrMsg: action.errMsg,
      };
    // List insurance embed
    case types.GETTING_INSURANCE_EMBED_LIST:
      return {
        ...state,
        gettingInsuranceEmbed: true,
        insuranceEmbed: null,
        getInsuranceEmbedErrMsg: "",
      };
    case types.GET_INSURANCE_EMBED_LIST_SUCCESS:
      return {
        ...state,
        gettingInsuranceEmbed: false,
        insuranceEmbed: action.insuranceEmbed,
        getInsuranceEmbedErrMsg: "",
      };
    case types.GET_INSURANCE_EMBED_LIST_FAIL:
      return {
        ...state,
        gettingInsuranceEmbed: false,
        insuranceEmbed: null,
        getInsuranceEmbedErrMsg: action.errMsg,
      };
    default:
      return state;
  }
};
