import { types } from './appparams.type';

export const updateUserInfo = (userInfo) => {
    return {
        type: types.UPDATE_USER_INFO,
        userInfo,
    }
};

export const getOauthCode = (oauthInfo) => {
    return {
        type: types.GET_OAUTH_CODE_SUCCESS,
        oauthInfo,
    }
};

export const getTokenUser = (cb = () => {}) => {
    return {
        type: types.GETTING_TOKEN,
        cb,
    }
}

export const getOauthV1Code = (cb = () => {}) => {
    return {
        type: types.GET_OAUTH_CODE,
        cb,
    }
}