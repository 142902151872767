export const types = {
  GETTING_PERSONAL_ACCIDENT_INSURANCE_DETAIL:
    "GETTING_PERSONAL_ACCIDENT_INSURANCE_DETAIL",
  GET_PERSONAL_ACCIDENT_INSURANCE_DETAIL_SUCCESS:
    "GET_PERSONAL_ACCIDENT_INSURANCE_DETAIL_SUCCESS",
  GET_PERSONAL_ACCIDENT_INSURANCE_DETAIL_FAIL:
    "GET_PERSONAL_ACCIDENT_INSURANCE_DETAIL_FAIL",
  GETTING_MOTOBIKE_INSURANCE_DETAIL: "GETTING_MOTOBIKE_INSURANCE_DETAIL",
  GET_MOTOBIKE_INSURANCE_DETAIL_SUCCESS:
    "GET_MOTOBIKE_INSURANCE_DETAIL_SUCCESS",
  GET_MOTOBIKE_INSURANCE_DETAIL_FAIL: "GET_MOTOBIKE_INSURANCE_DETAIL_FAIL",
  GETTING_INSURANCE_ALL_PRODUCT: "GETTING_INSURANCE_ALL_PRODUCT",
  GET_INSURANCE_ALL_PRODUCT_SUCCESS: "GET_INSURANCE_ALL_PRODUCT_SUCCESS",
  GET_INSURANCE_ALL_PRODUCT_FAIL: "GET_INSURANCE_ALL_PRODUCT_FAIL",

  GETTING_INSURANCE_CAR_PRODUCT: "GETTING_INSURANCE_CAR_PRODUCT",
  GET_INSURANCE_CAR_PRODUCT_SUCCESS: "GET_INSURANCE_CAR_PRODUCT_SUCCESS",
  GET_INSURANCE_CAR_PRODUCT_FAIL: "GET_INSURANCE_CAR_PRODUCT_FAIL",

  GETTING_CAR_INSURANCE_DETAIL: "GETTING_CAR_INSURANCE_DETAIL",
  GET_CAR_INSURANCE_DETAIL_SUCCESS:
    "GET_CAR_INSURANCE_DETAIL_SUCCESS",
  GET_CAR_INSURANCE_DETAIL_FAIL: "GET_CAR_INSURANCE_DETAIL_FAIL",

  GETTING_INSURANCE_HOME_PRODUCT: "GETTING_INSURANCE_HOME_PRODUCT",
  GET_INSURANCE_HOME_PRODUCT_SUCCESS: "GET_INSURANCE_HOME_PRODUCT_SUCCESS",
  GET_INSURANCE_HOME_PRODUCT_FAIL: "GET_INSURANCE_HOME_PRODUCT_FAIL",

  GETTING_HOME_INSURANCE_DETAIL: "GETTING_HOME_INSURANCE_DETAIL",
  GET_HOME_INSURANCE_DETAIL_SUCCESS:
    "GET_HOME_INSURANCE_DETAIL_SUCCESS",
  GET_HOME_INSURANCE_DETAIL_FAIL: "GET_HOME_INSURANCE_DETAIL_FAIL",
};
