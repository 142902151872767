import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import "../stylesComponents/InsuranceInfo.scss"
import Utils from 'utils/utils';
import { LIST_CAR_TYPE, TAS_PRODUCT_CODES } from 'utils/const';
import Select from 'components/CarProduct/component/Select';
import FloatInput from 'components/FloatInput/FloatInput';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from 'stores/companies/company.action';
import { getInsuranceCarProduct } from 'stores/insurance/insurance.action';
import ZaloPaySDK from 'utils/zalopay';
import { scrollToRefObject } from 'utils/helper';
import { useQuery } from 'screens/HomeScreen/HomeScreen';

const InsuranceInfo = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const company = useQuery().get('company');
    // const { companies } = useSelector((state) => state.company);
    const { insuranceCarProduct, gettingInsuranceCarProduct } = useSelector((state) => state.insurance);

    const refScrollCarType = useRef();
    const refCarType = useRef();
    const refScrollSeats = useRef();
    const refSeat = useRef();
    const refScrollCompany = useRef();
    const refCompany = useRef();

    const [carType, setCarType] = useState({});
    const [isValidCarType, setIsValidCarType] = useState(false);
    const [seat, setSeat] = useState("");
    const [isValidSeat, setIsValidSeat] = useState(false);
    const [isSeatEmpty, setIsSeatEmpty] = useState(false);
    const [listCompany, setListCompany] = useState([]);
    const [itemCompany, setItemCompany] = useState({});
    const [isValidCompany, setIsValidCompany] = useState(false);
    const [feeInsurance, setFeeInsurance] = useState(0);
    const [isBlur, setIsBlur] = useState(false);
    const [insuranceDetail, setInsuranceDetail] = useState({});

    const title = "Bảo hiểm bắt buộc TNDS của chủ xe ô tô";

    // useEffect(() => {
    //     dispatch(getCompanies({ langId: "vi" }))
    // }, []);

    useEffect(() => {
        if (gettingInsuranceCarProduct) {
          ZaloPaySDK.showLoading();
        } else {
          ZaloPaySDK.hideLoading();
        }
    }, [gettingInsuranceCarProduct]);

    useEffect(() => {
        if(insuranceCarProduct && insuranceCarProduct.car.length) {
            setListCompany(insuranceCarProduct.car.map(m => {
                return {
                    name: m.company.name,
                    code: m.company.id+"",
                    nameWithType: m.company.name
                }
            }))
            setFeeInsurance(insuranceCarProduct.car[0].premium);
            props.getFee(insuranceCarProduct.car[0].premium);

            if(company) {
                const itemInsurance = insuranceCarProduct.car.find(f => f.company.code === company);
                // const { company } = itemInsurance
                setItemCompany({
                    name: itemInsurance.company.name,
                    code: itemInsurance.company.id + ""
                });
                setInsuranceDetail(itemInsurance)
            }
        }
    }, [insuranceCarProduct])
    
    // useEffect(() => {
    //     if(companies.length) { setListCompany(companies.map((m) => ({
    //         name: m.name,
    //         code: m.id+"",
    //         nameWithType: m.name
    //     }))) }
    // }, [companies]);

    useEffect(() => {
        if(Object.keys(carType).length && seat && !isValidSeat && isBlur) {
            const data = {
                usePurpose: carType.code.usePurpose,
                goodsCap: 0,
                carInfo: carType.code.carInfo+"",
                stateAmountInsurances: {},
                seat: +seat
            };
            dispatch(getInsuranceCarProduct({data}));
        }
    }, [carType, seat, isBlur])
    
    const setCheckIsSeat = (carInfo, seat) => {
        if(carInfo === 19) {
            if(seat > 5 || seat < 2) {
                setIsValidSeat(true)
            }else {
                setIsValidSeat(false);
            }
        }else {
            if(seat > 50 || seat < 2) {
                setIsValidSeat(true)
            }else {
                setIsValidSeat(false);
            }
        }
    }

    const onChangeCarType = useCallback(
        (value) => {
            setCarType(value);
            setIsValidCarType(!value.code);
            if(value && seat) {
                setCheckIsSeat(value.code.carInfo, seat);
            }else {
                setIsValidSeat(false);
            }
        },
        [seat],
    )
    
    const onSeatChange = useCallback(
        (value) => {
            setSeat(value);
            setIsSeatEmpty(!value)
            setIsBlur(false);
            if(value && Object.keys(carType).length) {
                setCheckIsSeat(carType.code.carInfo, value);
            }else {
                setIsValidSeat(false);
            }
        },
        [carType],
    )
    
    const onEndEditingSeat = () => {
        setIsBlur(true);
    }

    const onChangeCompany = (value) => {
        setItemCompany(value);
        setIsValidCompany(!value.code);
        setInsuranceDetail(insuranceCarProduct.car.find(f => f.company.id + "" === value.code))
    }

    useImperativeHandle(
        ref, () => ({
            validInsuranceInfo() {
                setIsValidCarType(!carType.code);
                setIsSeatEmpty(!seat);
                setIsValidCompany(!itemCompany.code);
                if(!carType.code) {
                    scrollToRefObject(refScrollCarType);
                }
                if(!seat) {
                    scrollToRefObject(refScrollSeats);
                }
                if(!itemCompany.code) {
                    scrollToRefObject(refScrollCompany);
                }
                return !!carType.code && !isValidSeat && !isSeatEmpty && !!itemCompany.code;
            },
            getInsuranceInfo() {
                return {
                    seat,
                    carType,
                    itemCompany
                }
            },
            getInsuranceDetail() {
                return insuranceDetail
            }
        })
    )

    return (
        <div className="insurance-info bg margin-top-40 margin-h-16 flex">
            <div className="bg-white flex pos-relative w-100 insurance-info-wrapper">
                <div className="title-insurance color-black">{title}</div>
                <div className="maximum-limit">
                    <p>Mức trách nhiệm dân sự về người thứ ba</p>
                    <p>- Về người: 150.000.000đ/người/vụ</p>
                    <p>- Về tài sản: 100.000.000đ/vụ</p>
                </div>
                <div className="form_row" ref={refScrollCarType}>
                    <Select
                        ref={refCarType}
                        title={'Loại xe'}
                        placeholder={'Chọn loại xe'}
                        data={LIST_CAR_TYPE}
                        selectedValue={carType}
                        disabled={!LIST_CAR_TYPE}
                        onValueChange={onChangeCarType}
                        isSearch={false}
                        require
                        heightModal="30vh"
                        error={isValidCarType}
                    />
                </div>
                <div className="form_row" ref={refScrollSeats}>
                    <FloatInput
                        ref={refSeat}
                        label={isSeatEmpty ? "Vui lòng nhập số chỗ ngồi" : isValidSeat ? "Vượt quá giới hạn chỗ ngồi" : "Nhập số chỗ ngồi" }
                        type="number"
                        iconClear={true}
                        value={seat}
                        onChangeText={onSeatChange}
                        onEndEditing={onEndEditingSeat}
                        error={isValidSeat || isSeatEmpty}
                        require
                    />
                </div>
                <div className="price-time">
                    <div className="title color-gray">Phí bảo hiểm: / 1 năm</div>
                    <div className="value color-blue">{Utils.formatMoneyVND(feeInsurance)}đ</div>
                </div>
                <div className="form_row" ref={refScrollCompany}>
                    <Select
                        ref={refCompany}
                        title={'Công ty bảo hiểm'}
                        placeholder={'Chọn công ty bảo hiểm'}
                        data={listCompany}
                        selectedValue={itemCompany}
                        disabled={true}
                        onValueChange={onChangeCompany}
                        error={isValidCompany}
                        heightModal="50vh"
                    />
                </div>
            </div>
        </div>
    )
});

export default React.memo(InsuranceInfo);