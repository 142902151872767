import React, { useRef, useState } from 'react';
import "./RenewalScreen.scss"
import { SafeAreaView } from 'components/SafeAreaView';
import InsuranceInfo from './components/InsuranceInfo';
import CarInput from 'screens/CustomerInfoScreen/components/CarInput';
import colors from 'utils/colors';
import Utils from 'utils/utils';
import Button from 'components/Button';
import { useEffect } from 'react';
import ContactInput from 'screens/CustomerInfoScreen/components/ContactInput';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCities } from 'stores/address/address.action';
import { clearCheckPayment, clearErrMess, createOrder } from 'stores/payment/payment.action';
import ZaloPaySDK from 'utils/zalopay';
import { PATH, TYPE_INSURANCE } from 'utils/const';
import { useQuery } from 'screens/HomeScreen/HomeScreen';
import MotobikeInput from 'screens/CustomerInfoScreen/components/MotobikeInput';

const RenewalScreen = () => {
  const productType = useQuery().get('product_type');

  const vehicleRef = useRef();
  const insuranceInfoRef = useRef();
  const contactRef = useRef();

  const dispatch = useDispatch();
  const history = useHistory();
  const [fee, setFee] = useState(0);

  const {
    responseCreateOrder,
    insurance,
    createOrderData,
    creatingOrder,
    createOrderErrMsg,
    hasPayment,
  } = useSelector((state) => state.payment);

  const getFee = (value) => setFee(value);

  const onNextStepPress = () => {
    const insuranceDetail = getInsuranceDetail(insuranceInfoRef);
    // get form insuranceInfo
    const insuranceInfo = getInsuranceInfo(insuranceInfoRef);
    const isValidInsuranceInfo = validInsuranceInfo(insuranceInfoRef);
    // get form vehicleInfo
    const vehicleInfo = getVehicleInfo(vehicleRef);
    const isValidVehicleInfo = validVehicleInfo(vehicleRef);
    // get form contract
    const contract = getCustomerInfo(contactRef);
    const isValidContract = contactValid(contactRef);
    if (isValidVehicleInfo && isValidContract && isValidInsuranceInfo) {
      const key = productType === TYPE_INSURANCE.CAR ? "_convertLicensePlatesCar" : "_convertLicensePlates";
      vehicleInfo.licensePlates = Utils[key](vehicleInfo.licensePlates);
      dispatch(
        createOrder(productType, {...insuranceDetail, status: "waiting_for_approve", isSendZNSNotify: true}, contract, vehicleInfo, {
          addressRecipient: "",
          cityRecipient: {},
          districtRecipient: {},
          fullNameRecipient: "",
          phoneRecipient: "",
          wardRecipient: {}
        })
      );
    }
  };

  useEffect(() => {
    if (creatingOrder) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [creatingOrder]);

  useEffect(() => {
    if (createOrderErrMsg) {
      const errMsg = createOrderErrMsg;
      ZaloPaySDK.showDialog({
        message: errMsg,
        buttons: [{ text: "Đóng" }],
      });
      return () => {
        dispatch(clearErrMess());
      }
    }
  }, [createOrderErrMsg]);

  useEffect(() => {
    if (responseCreateOrder && createOrderData && insurance && hasPayment) {
      dispatch(clearCheckPayment());
      history.push({
        pathname: `${PATH}/contract-review/${responseCreateOrder.quotation_code}`,
        search: "subview=cashier"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCreateOrder, createOrderData, hasPayment]);

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  return (
    <div>
      <SafeAreaView id="wrapper-scroll" style={styles}>
        <div style={{ paddingBottom: 10 }}>
          <div className="margin-bottom-16">
            <InsuranceInfo ref={insuranceInfoRef} getFee={getFee} />
          </div>
          <div className='form'>
            {productType === TYPE_INSURANCE.MOTOBIKE ? (
              <MotobikeInput ref={vehicleRef} productCode={""} />
            ) : productType === TYPE_INSURANCE.CAR ? <CarInput ref={vehicleRef} productCode={""} /> : null}
            <ContactInput
              ref={contactRef}
              minDateEffect={1}
              productCode={""}
              minAge={0}
              maxAge={0}
              insuranceDetail={{}}
            />
          </div>
        </div>
      </SafeAreaView>
      <div className="button-fixed-bottom">
        <div className="price">{Utils.formatMoneyVND(fee)}đ</div>
        <Button
          className="btn-main"
          onPress={onNextStepPress}
          style={{ backgroundColor: colors.main }}
        >
          Tiếp tục
        </Button>
      </div>
    </div>
  )
}

const styles = {
  height: "calc(100vh - env(safe-area-inset-bottom) - 76px)",
  bottom: 76,
  position: "fixed",
  top: 0,
  overflowY: 'auto'
};

export default RenewalScreen;

const validInsuranceInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.validInsuranceInfo();
  }
}

const getInsuranceDetail = (ref) => {
  if (ref && ref.current) {
    return ref.current.getInsuranceDetail();
  }
}

const getInsuranceInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getInsuranceInfo();
  }
}

const validVehicleInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const getVehicleInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getVehicleInfo();
  }
};

const getCustomerInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getCustomerInfo();
  }
};

const contactValid = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};