export const APPID = 1020;
export const APPID_CAR = 3507;
export const APPID_HOME = 3835;

export const ZPI_AGREEMENT_PAY = process.env.REACT_APP_ZPI_AGREEMENT_PAY

export const PATH = "/insurance" || process.env.PUBLIC_URL;

export const NODE_ENV = {
	DEVELOPMENT: 'development',
	PRODUCTION: 'production'
}

export const API_KEY = process.env.REACT_APP_API_KEY || '';

export const ERROR_MESSAGES = {
	NORMAL: 'Có lỗi xảy ra, xin vui lòng thử lại sau!',
	LOGIN_FAIL: 'Có lỗi xảy ra, không lấy được thông tin người dùng!',
	_LOGIN_FAIL: 'Bạn cần chia sẻ thông tin đề vào luồng mua bảo hiểm!',
	ADDRESS_FAIL: 'Có lỗi xảy ra, không lấy được thông tin địa chỉ!',
	BRAND_FAIL: 'Có lỗi xảy ra, không lấy được thông tin hãng xe'
}

export const TYPE_INSURANCE = {
	ACCIDENT: "accident",
	MOTOBIKE: "motobike",
	CAR: "car",
	HOME: "home"
}

export const TYPE_CONTRACT = {
	PAID: "paid",
	UNPAID: "unpaid",
}

export const FILTER_CONTRACT = {
	ALL: "all",
	ACCIDENT: "accident",
	MOTOBIKE: "motobike",
	CAR: "car"
}

export const BILLING_TYPE = {
    yearly: 'Yearly',
    semiAnnually: 'Semi-annually',
    quarterly: 'Quarterly',
	'30days': '30days',
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily'
};

export const PRODUCT_TYPE = {
	human: 'human',
	car: 'car',
	personal_accident: 'personal_accident',
	hospital_cash: 'hospital_cash',
	motorbike_liability: 'motorbike_liability',
	travel: 'travel',
	home: 'home',
	device: 'device',
	critical_illness: 'critical_illness',
	cyber: 'cyber',
  };

export const UIC_PRODUCT_CODES = ['ZUI-PA'];

export const VNI_PRODUCT_CODES = ['VNI-O50-MB', 'VNI-U50-MB'];

export const HDI_PRODUCT_CODES = ['HDI-O50-MB', 'HDI-U50-MB', 'HDI-Car01'];

export const ABIC_PRODUCT_CODES = ['ABIC-MTO50', 'ABIC-Car01', 'ABIC-HOME4', 'ABIC-HOME1', 'ABIC-HOME2', 'ABIC-HOME3'];

export const ABIC_PRODUCT_CODES_HOME = ['ABIC-HOME4', 'ABIC-HOME1', 'ABIC-HOME2', 'ABIC-HOME3'];

export const ABIC_PRODUCT_CODES_MOTO_CAR = ['ABIC-MTO50', 'ABIC-Car01'];

export const BSH_PRODUCT_CODES = ['BSH-MTO50', 'BSH-Car01'];

export const MIC_PRODUCT_CODES = ['MIC-Car01'];

export const TAS_PRODUCT_CODES = ['TAS-Car01'];

export const USE_PURPOSE_CAR = {
	roadster: 'Xe không kinh doanh vận tải',
	car: 'Xe kinh doanh vận tải',
	// truck: 'Xe chở hàng (Xe tải)',
};

export const LIST_HOME_TYPE = {
	villa: "Nhà biệt thự",
	apartment: "Nhà chung cư",
	independent: "Nhà độc lập",
	town: "Nhà liền kề"
}

export const LIST_CAR_TYPE = [{
	code: {
		usePurpose: "non_commercial",
		seat: 5,
		carInfo: 1
	},
	name: "Xe chở người không kinh doanh vận tải",
	nameWithType: "Xe chở người không kinh doanh vận tải",
},{
	code: {
		usePurpose: "commercial",
		seat: 5,
		carInfo: 1
	},
	name: "Xe chở người kinh doanh vận tải",
	nameWithType: "Xe chở người kinh doanh vận tải",
},{
	code: {
		usePurpose: "non_commercial",
		seat: 2,
		carInfo: 19
	},
	name: "Xe vừa chở người vừa chở hàng (pickup, minivan)",
	nameWithType: "Xe vừa chở người vừa chở hàng (pickup, minivan)",
}]
