import {put, takeLatest} from 'redux-saga/effects';
import { AppApiInstance } from '../../api/app-api';
import { ERROR_MESSAGES } from 'utils/const';
import { types } from './carInfomation.type';
import ZaloPaySDK from 'utils/zalopay';

function* getBrandsAndModelsTASCO() {
  try {
    const dataParse = yield AppApiInstance.getBrandsTasco();
    if(dataParse && dataParse.data && dataParse.data.getCarBrandsAndModelTasco){
      yield put({ type: types.GET_CAR_BRANDS_SUCCESS, brands: dataParse.data.getCarBrandsAndModelTasco});
    }else {
      yield put({ type: types.GET_CAR_BRANDS_FAIL, isError: true });
      ZaloPaySDK.showDialog({message: ERROR_MESSAGES.BRAND_FAIL, buttons: [{text: 'Đóng'}]});
    }
  } catch (err) {
    yield put({ type: types.GET_CAR_BRANDS_FAIL, isError: true });
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES.BRAND_FAIL, buttons: [{text: 'Đóng'}]});
  }
}

function* getBrandsMIC() {
  try {
    const dataParse = yield AppApiInstance.getBrandsMIC();
    if(dataParse && dataParse.data && dataParse.data.getCarBrandsMIC){
      yield put({ type: types.GET_CAR_BRANDS_SUCCESS, brands: dataParse.data.getCarBrandsMIC});
    }else {
      yield put({ type: types.GET_CAR_BRANDS_FAIL, isError: true });
      ZaloPaySDK.showDialog({message: ERROR_MESSAGES.BRAND_FAIL, buttons: [{text: 'Đóng'}]});
    }
  } catch (err) {
    yield put({ type: types.GET_CAR_BRANDS_FAIL, isError: true });
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES.BRAND_FAIL, buttons: [{text: 'Đóng'}]});
  }
}

function* getModelsMIC({ brand, callback }) {
  try {
    const dataParse = yield AppApiInstance.getModelsMIC({ brand });
    if(dataParse && dataParse.data && dataParse.data.getCarModelsMIC){
      yield put({ type: types.GET_CAR_MODELS_SUCCESS});
      yield callback(dataParse.data.getCarModelsMIC);
    }else {
      yield put({ type: types.GET_CAR_MODELS_FAIL, isError: true });
      ZaloPaySDK.showDialog({message: ERROR_MESSAGES.BRAND_FAIL, buttons: [{text: 'Đóng'}]});
    }
  } catch (err) {
    yield put({ type: types.GET_CAR_MODELS_FAIL, isError: true });
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES.BRAND_FAIL, buttons: [{text: 'Đóng'}]});
  }
}

export function* carInfomationWatcher() {
  yield takeLatest(types.GETTING_CAR_BRANDS_MODELS_TASCO, getBrandsAndModelsTASCO);
  yield takeLatest(types.GETTING_CAR_MIC_BRANDS, getBrandsMIC);
  yield takeLatest(types.GETTING_CAR_MIC_MODELS, getModelsMIC);
}