import { Alert } from "../components/Alert";
import jwt_decode from 'jwt-decode';
import { store } from '../stores/configStore';
import { getOauthCode, updateUserInfo } from "../stores/appparams/appparams.action";
import { ShowLoading, HideLoading } from "../components/Loading";
import Utils from "./utils";

const ZaloPay = window.ZaloPay;
const ZPI = window.ZPI_SPA_SDK;
const ZPIV2 = window.zlpSdk;

export default class ZaloPaySDK {

    static setTitle = (title) => {
        document.title = title;
        ZPI && ZPI.setTitle(title);
    }

    static setTitleV2 = (title) => {
        ZPIV2.UI
            .setTitle({ title: title })
            .then((response) => console.log(response))
            .catch((error) => console.error(error.errorCode));
    }

    static openInternalBrowser = (url) => {
        ZPIV2.Navigator
            .openInternalBrowser({ url: url })
            .then((response) => console.log(response))
            .catch((error) => console.error(error.errorCode));
    }

    static openExternalBrowser = (url) => {
        ZPIV2.Navigator
            .openExternalBrowser({ url: url })
            .then((response) => console.log(response))
            .catch((error) => console.error(error.errorCode));
    }

    static showToast = ({mess, position}) => {
        ZPIV2.UI
            .showToast({ message: mess, position: position })
            .then((response) => console.log(response))
            .catch((error) => console.error(error.errorCode));
    }

    static isZaloPay = function () {
        return (store.getState().appParams.environment === 'ZPA') || (navigator.userAgent.indexOf("ZaloPayClient") > -1);
    }

    static isIOS12 = () => {
        return (navigator.userAgent.indexOf("iPhone OS 12") > -1);
    }

    static isMobileDevice = () => {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent));
    };

    static isZPI = function () {
        return store.getState().appParams.environment === 'zpi';
    }

    static isWeb = function () {
        return store.getState().appParams.environment === 'web';
    }

    static isAndroid = function () {
        const isZPIAndroid = !!navigator.userAgent.match(/android/i);
        const isZaloPayAndroid = ZaloPay && (/android/i).test(ZaloPay.ua);
        return isZPIAndroid || isZaloPayAndroid;
    }

    static isIOS = function () {
        const isZPIIOS = !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
        const isZaloPayIOS = ZaloPay && (/iphone|ipad|ipod/i).test(ZaloPay.ua);
        return isZPIIOS || isZaloPayIOS;
    }

    static writeLog = (...args) => {
        if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_ENV !== 'sandbox') {
            return;
        }
        var time = (+new Date());
        var formated_time = new Date().toLocaleString();
        var arg = [].slice.call(args);
        var type = arg[0].toLowerCase().trim();
        switch (type) {
            case "error":
                arg.splice(0, 1);
                arg.length === 1 ? console.error(formated_time, arg[0]) : console.error(formated_time, arg);
                break;
            case "warn":
                arg.splice(0, 1);
                arg.length === 1 ? console.warn(formated_time, arg[0]) : console.warn(formated_time, arg);
                break;
            case "info":
                arg.splice(0, 1);
                arg.length === 1 ? console.log(formated_time, arg[0]) : console.log(formated_time, arg);
                break;
            default:
                type = "info";
                arg.length === 1 ? console.log(formated_time, arg[0]) : console.log(formated_time, arg);
                break;
        }
        if (ZaloPay && ZaloPay.isDebug && ZaloPay.call && window.ZaloPayJSBridge && window.ZaloPayJSBridge.call) {
            var opt = {
                type: type,
                time: time,
                data: JSON.stringify(arg)
            };
            ZaloPay.call("writeLog", opt);
        }
    }

    static setUserInfo = (userInfo) => {
        this.writeLog("info", "APP - getAppUserInfo: ", userInfo);
        let muid = "";
        let maccesstoken = "";
        let userid = "";
        let displayname = '';
        let avatarurl = '';

        if (this.isZaloPay()) {
            if (userInfo.muid) {
                muid = userInfo.muid;
            }
            if (userInfo.maccesstoken) {
                maccesstoken = userInfo.maccesstoken;
            }
            if (userInfo.userid) {
                userid = userInfo.userid;
            }
            if (userInfo.displayname) {
                displayname = userInfo.displayname
            }
            if (userInfo.avatarurl) {
                avatarurl = userInfo.avatarurl
            }
        } else {
            if (userInfo.MUID) {
                muid = userInfo.MUID;
            }
            if (userInfo.MAccessToken) {
                maccesstoken = userInfo.MAccessToken;
            }
            if (userInfo.ZaloPayID) {
                userid = userInfo.ZaloPayID;
            }
            if (userInfo.displayname) {
                displayname = userInfo.displayname
            }
            if (userInfo.avatarurl) {
                avatarurl = userInfo.avatarurl
            }
        }

        return { muid, maccesstoken, userid, displayname, avatarurl }
    }

    static inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    static getOauthV1Code = async (appid, cb) => {
        const codeVerifier = Utils.generateCodeVerifier();
        const codeChallenge = await Utils.generateCodeChallenge(codeVerifier);
        const appInfo = await ZPIV2.Device.appInfo();
        console.log(appInfo);
        console.log("Code verifier: ", codeVerifier)
        console.log("Code challenge: ", codeChallenge);
        try {
            ZPIV2.User.getOauthV1Code({ appId: appid, codeChallenge: codeChallenge })
                .then((data) => {
                    console.log("getOauthV1Code response", data);
                    store.dispatch(getOauthCode({ environment: appInfo.data.platform, oauthCode: data.data.code, verifierCode: codeVerifier }))
                    cb({});
                })
                .catch((error) => {
                    console.log('getOauthV1Code Error: ', error);
                    cb({});
                });
        } catch (error) {
            console.log('getOauthV1Code Error: ', error);
            cb({});
        }
    }

    static getAppUserInfoChangeTab = (appid, cb) => {
        console.log("APPID change tab: ", appid)
        var self = this;
        if (this.isZaloPay()) {
            ZaloPaySDK.writeLog('ZaloPay change tab: ', ZaloPay);
            ZaloPay.ready(() => {
                ZaloPay.getAppUserInfo(appid, function (userinfo) {
                    let userInfo = self.setUserInfo(userinfo.data);
                    store.dispatch(updateUserInfo({ ...userInfo, environment: 'ZPA' }));
                    cb({});
                });
            });
        } else {
            try {
                console.log('ZPI change tab: ', ZPI);
                if (ZPI && !this.inIframe()) {
                    window.setUserInfo = (userinfo) => {
                        let userInfo = this.setUserInfo(userinfo);
                        userInfo && store.dispatch(updateUserInfo({ ...userInfo, environment: 'web' }))
                    };
                    cb({}); return;
                }

                ZPI && ZPI.getProfile(appid)
                    .then((data) => {
                        console.log('getProfile change tab', data);
                        if (data && data.data && data.data.token) {                                
                            const token = data.data.token;
                            const decoded = jwt_decode(token);
                            
                            const displayName = data.data.userInfo ? data.data.userInfo.displayName : '';
                            const avatarUrl = data.data.userInfo ? data.data.userInfo.avatar : '';
                            let userInfo = this.setUserInfo({...decoded, displayname: displayName, avatarurl: avatarUrl});
                            
                            store.dispatch(updateUserInfo({ ...userInfo, environment: 'zpi' }));
                            cb(userInfo);
                        } else {
                            console.log('getUserInfo data wrong', data);
                            cb({});

                        }
                    })
                    .catch((error) => {
                        console.log('getUserInfo Error: ', error);
                        cb({});

                    });
            } catch (e) {
                cb({});
            }
        }
    }

    static getAppUserInfo = (appid, cb) => {
        console.log("APPID: ", appid)
        var self = this;
        if (this.isZaloPay()) {
            ZaloPaySDK.writeLog('ZaloPay: ', ZaloPay);
            ZaloPay.ready(() => {
                ZaloPay.getAppUserInfo(appid, function (userinfo) {
                    let userInfo = self.setUserInfo(userinfo.data);
                    store.dispatch(updateUserInfo({ ...userInfo, environment: 'ZaloPay' }));
                    cb({});
                });
            });
        } else {
            try {
                window.addEventListener('load', () => {
                    console.log('ZPI: ', ZPI);
                    if (ZPI && !this.inIframe()) {
                        window.setUserInfo = (userinfo) => {
                            let userInfo = this.setUserInfo(userinfo);
                            userInfo && store.dispatch(updateUserInfo({ ...userInfo, environment: 'web' }))
                        };
                        cb({}); return;
                    }

                    ZPI && ZPI.getProfile(appid)
                        .then((data) => {
                            console.log('getProfile', data);
                            if (data && data.data && data.data.token) {                                
                                const token = data.data.token;
                                const decoded = jwt_decode(token);
                                
                                const displayName = data.data.userInfo ? data.data.userInfo.displayName : '';
                                const avatarUrl = data.data.userInfo ? data.data.userInfo.avatar : '';
                                let userInfo = this.setUserInfo({...decoded, displayname: displayName, avatarurl: avatarUrl});
                                
                                store.dispatch(updateUserInfo({ ...userInfo, environment: 'zpi' }));
                                cb(userInfo);
                            } else {
                                console.log('getUserInfo data wrong', data);
                                // this.getUserInfoFail();
                                cb({});

                            }
                        })
                        .catch((error) => {
                            console.log('getUserInfo Error: ', error);
                            // this.getUserInfoFail();
                            cb({});

                        });
                });
            } catch (e) {
                // this.getUserInfoFail();
                cb({});
            }
        }
    }

    static showLoading = () => {
        ShowLoading();
    };

    static hideLoading = () => {
        HideLoading();
    };

    static showDialog = ({ title = '', message = '', buttons = [{ text: 'OK' }], image }) => {
        Alert({ title, message: message, buttons: buttons, image });
    };

    static launchDeeplink = (url, cb = () => {}) => {
        ZaloPay && ZaloPay.launchDeeplink(url, cb)
    };

    static navigateTo = (url) => {
        ZPI && ZPI.navigateTo(url);
    }

    static tracking = (eventId, metaData) => {
        this.writeLog('tracking', eventId);
        if(this.isZaloPay()) {
            const zalopay_id = store.getState().appParams.userInfo.zalopay_userid;
            const evn = process.env.REACT_APP_ENVIRONMENT;
            const apiPath = {
                sandbox: 'https://socialstg.zalopay.vn/v2/tracking/event',
                staging: 'https://socialstg.zalopay.vn/v2/tracking/event',
                production: 'https://tracking.zalopay.vn/v2/tracking/event',
            };
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const params = {
                timestamp: new Date().toISOString(),
                event_id: eventId,
                metadata: metaData,
                zalopay_id: zalopay_id,
            };
            const method = 'POST';
            return fetch(apiPath[evn], {
                method,
                headers,
                body: JSON.stringify({ ...params })
            })
            .then(resp => resp)
            .catch(error => Promise.reject({ ...error }));
        }else {
            let data = { eventID: eventId, metaData: metaData };
            console.log('tracking ZPI: ',data);
            ZPI && ZPI.tracking(data);
        }

        
    }

    static payOrder = (data, cb) => {
        const { environment } = store.getState().appParams;
        // alert(`environment: ${environment}. order_url: ${data.order_url}`);
        switch (environment) {
            // case "ZPA":
            //     ZaloPay && ZaloPay.payOrder(data, cb);
            //     break;
            case "web":
                return window.open(data.order_url, '_self');
            // case "zpi":
            //     ZPI && ZPI.showOrderPopup({ zpTransToken: data.zptranstoken, zpAppID: data.appid }, cb);
            case "ZPA":
            case "ZPI":
                ZPIV2.Payment.startCashier({
                    orders: [{
                        order_type: 1,
                        order: {
                            app_id: data.appid,
                            zp_trans_token: data.zptranstoken,
                        },
                    }],
                    options: {},
                    callback: (resp) => cb(resp)
                });
                break;
            default:
                return window.open(data.order_url, '_self');
        }
    }

    static handleBlurInput = () => {
        if (ZPI) {
            ZPI.handleBlurInput();
        }
    }

    static share = ({ title, url }) => {
        const msg = `Cùng ZaloPay chung tay ủng hộ chương trình ${title}. Bấm vào link để tham gia: ${url}`
        if (navigator.share) {
            navigator.share({
                text: msg,
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            if (this.isZaloPay()) {
                ZaloPay.share({
                    type: 2,
                    caption: msg,
                    content: url,
                    showShareButton: false
                })
            }
        }
    }

    static closeWindow = () => {
        ZaloPay.closeWindow();
    };

}