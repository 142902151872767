import React, { useEffect, useRef, useState } from 'react'
import EmptyList from 'components/EmptyList'
import { SafeAreaView } from 'components/SafeAreaView'
import "./SearchProductExternalScreen.scss"
import ZaloPaySDK from 'utils/zalopay'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { getInsuranceEmbed } from 'stores/contract/contract.action'
import { Ticket } from 'components/Ticket/Ticket'
import moment from 'moment'
import images from 'utils/images'
import Util from "../../utils/utils";
import { contractStatusAdapter } from 'utils/helper'
import { PATH, TYPE_CONTRACT } from 'utils/const'
import Modal from "react-modal";
import colors from 'utils/colors';
import Button from 'components/Button';

const allCharacter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
                      'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 
                      'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 
                      't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const SearchProductExternalScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { 
    insuranceEmbed, 
    gettingInsuranceEmbed
  } = useSelector(
    (state) => state.contract
  );
  
  const [phoneNumber, setPhoneNumber] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [isOpenCaptcha, setIsOpenCaptcha] = useState(false);
  const [isCheckCaptcha, setIsCheckCaptcha] = useState(false);
  const [indexCallApi, setIndexCallApi] = useState(0);
  const inputCaptchaRef = useRef();

  useEffect(() => {
    ZaloPaySDK.setTitleV2("Tra cứu bảo hiểm điện tử");
    randomCaptcha()
  }, []);
  
  useEffect(() => {
    if (gettingInsuranceEmbed) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
      setIsOpenCaptcha(false);
    }
  }, [gettingInsuranceEmbed]);

  const randomCaptcha = () => {
    let stringCaptcha = [];
    for(let i = 0; i < 6; i++) {
      let randomChar = allCharacter[Math.floor(Math.random() * allCharacter.length)];
      stringCaptcha.push(randomChar);
    }
    setCaptcha(stringCaptcha.join(""));
  }

  const handleClickSearch = () => {
    if(Util.validatePhone(phoneNumber)) {
      setIsOpenCaptcha(true);
      setIsCheckCaptcha(false);
    }
    setIsCheckCaptcha(!Util.validatePhone(phoneNumber));
    randomCaptcha();
  }

  const refreshString = () => { 
    randomCaptcha();
    setIsCheckCaptcha(false);
  }

  const handleCheckCaptcha = () => {
    const valueCheck = inputCaptchaRef.current.value;
    if(valueCheck === captcha) {
      dispatch(getInsuranceEmbed(phoneNumber.replace("0", "84")));
      setIndexCallApi(1)
    }
    setIsCheckCaptcha(valueCheck !== captcha);
  }

  useEffect(() => {
    if(indexCallApi < 2 && insuranceEmbed && insuranceEmbed.length === 0 && phoneNumber && !isCheckCaptcha) {
      dispatch(getInsuranceEmbed(phoneNumber));
      setIndexCallApi(2)
    }
  }, [insuranceEmbed])
  
  return (
    <div className='search-external'>
      <SafeAreaView  id="wrapper-scroll" style={styles}>
        <div className='search-input'>
          <div className='text-noti'>* Lưu ý: Chỉ tra cứu các hợp đồng bảo hiểm nhà tư nhân của bảo hiểm PVI</div>
          <div className='search-group'>
            <div style={{
              border: "1px solid #e4e6e5",
              padding: 12,
              fontSize: 14,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              borderRight: "none"
            }}>+84</div>
            <input onChange={(event) => {
              setPhoneNumber(event.target.value);
              setIsCheckCaptcha(false)
            }} type='number' className='input-phoneNumber' placeholder='Nhập số điện thoại' />
            <button onClick={handleClickSearch} className='btn-search'>Tra cứu</button>
          </div>
          <div style={{
            display: isCheckCaptcha ? "flex" : "block",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 6
          }}>
            {isCheckCaptcha && <span style={{
              color: "red",
              fontSize: 12,
              fontStyle: "italic"
            }}>{
              phoneNumber && !Util.validatePhone(phoneNumber) ? "Số điện thoại không hợp lệ" : "Vui lòng nhập số điện thoại"
            }</span>}
            <span onClick={() => history.push(`${PATH}/management?payment=${TYPE_CONTRACT.PAID}`)} style={{fontSize: 12, fontStyle: "italic", color: "blue", display: "flex", justifyContent: "flex-end"}}>{`>>`} Quản lý hợp đồng của bạn</span>
          </div>
          
        </div>
        <div className='list-policy'>
          {
            insuranceEmbed && insuranceEmbed.length !== 0 ?
                <div>
                  <div style={{
                    textAlign: "center",
                    color: "#006dff",
                    fontSize: "18px",
                    paddingBottom: 15
                  }}>
                    <b>Danh sách bảo hiểm điện tử</b>
                  </div>
                  {
                    insuranceEmbed.map((item) => {
                      return <div className="wrap-ticket" key={item.quotationCode}>
                        <Ticket
                          to={{ 
                            pathname: `${PATH}/external-contract-detail/${item.quotationCode}`,
                            search: `muid=${item.muid}`,
                          }}
                        >
                          <>
                            <div className="contract-code bg-white">
                              <div className="code">
                                <div className="title">Mã hợp đồng</div>
                                <div className="value color-blue">
                                  {item.quotationCode}
                                </div>
                              </div>
                              <div className="contract-price">
                                <div className="value">
                                  {Util.formatMoneyVND(item.amountInsurance)}đ
                                </div>
                                <div className="detail color-gray product-name">
                                  {`${item.nameProduct} 1 tháng`}
                                </div>
                              </div>
                            </div>
                            <div className="customer">
                              <div className="customer-detail flex-v-center">
                                <div className="icon margin-right-12">
                                  <img alt="" src={images.icon_home} />
                                </div>
                                <div>
                                  <div className="name">{item.name}</div>
                                </div>
                              </div>
                              {/* <div className="logo">
                                <img alt="" src={item.productLogo || item.companyLogo} />
                              </div> */}
                            </div>
                            <div className="valid-date">
                              <div className="validity-date">
                                <div className="title">Ngày hiệu lực</div>
                                <div className="date">
                                  {moment(item.effectiveDate).format("HH:mm DD/MM/YYYY")}
                                </div>
                              </div>
                              <div className="expire-date bg-white">
                                <div className="title">Ngày hết hạn</div>
                                <div className="date">
                                  {moment(item.expiryDate).format("HH:mm DD/MM/YYYY")}
                                </div>
                              </div>
                            </div>
                          </>
                          <div className="status">
                            <div className="title color-black">
                              <span
                                className={
                                  item.status === "approved"
                                    ? "color-green"
                                    : "color-orange"
                                }
                              >
                                {contractStatusAdapter(
                                  item.status,
                                  item.effectiveDate,
                                  item.expiryDate
                                )}
                              </span>
                            </div>
                          </div>
                        </Ticket>
                      </div>
                    })
                  }
                </div>
            : <EmptyList subTitle="Bạn chưa có gói bảo hiểm nào" />
          }
          <Modal
            isOpen={isOpenCaptcha}
            onRequestClose={() => setIsOpenCaptcha(false)}
            contentLabel="Validate Captcha"
            style={modalStyles}
            className="custom-modal-captcha"
          >
            <div className="head-modal">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="head-modal-title">Validate Captcha</div>
              </div>
              <Button
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "transparent",
                }}
                onPress={() => setIsOpenCaptcha(false)}
              >
                <div style={{ color: colors.main, padding: "16px 16px 0px" }}>
                  <img src={images.icon_close} alt="icon clear" />
                </div>
              </Button>
            </div>
            <div className='body-modal'>
              <div className='body-modal-captcha'>
                <div className='captcha-area'>
                  <div className='captcha-img'>
                    <img src={images.background_captcha} alt='background_captcha' />
                    <span className='captcha'>{captcha}</span>
                  </div>
                  <button onClick={refreshString} className='reload-btn'>
                    <img src={images.icon_refresh} alt='icon-refresh' />
                  </button>
                </div>
                <div className='input-area'>
                  <input onChange={() => {
                    setIsCheckCaptcha(false)
                  }} ref={inputCaptchaRef} type='text' placeholder='Nhập mã captcha' maxLength={6} required />
                  <button onClick={handleCheckCaptcha} className='btn-check'>Kiểm tra</button>
                </div>
                {isCheckCaptcha && <div style={{
                    color: "red",
                    fontSize: 16,
                    fontStyle: "italic",
                    marginTop: 8
                  }}>Mã captcha không hợp lệ</div>}
              </div>
            </div>
          </Modal>
        </div>
      </SafeAreaView>
    </div>
    
  )
}

const styles = {
  height: "calc(100vh - env(safe-area-inset-bottom) - 76px)",
  bottom: 76,
  position: "fixed",
  top: 0,
  overflowY: 'auto'
};

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(36, 39, 43, 0.5)",
    zIndex: 3,
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    width: "90%",
    height: "auto",
    border: "none",
    borderRadius: 8,
    background: colors.background,
  },
}

export default SearchProductExternalScreen