import { put, takeLatest } from "redux-saga/effects";
import { AppApiInstance } from "../../api/app-api";
import { types } from "./company.type";
import { ERROR_MESSAGES } from "../../utils/const";

function* getCompanies({ langId }) {
    try {
        const dataParse = yield AppApiInstance.getCompanies({ langId });
        yield put({ 
            type: types.GET_COMPANIES_SUCCESS, 
            companies: dataParse.data.getCompanies
        });
    } catch (err) {
        yield put({
            type: types.GET_COMPANIES_FAIL,
            errMsg: ERROR_MESSAGES.NORMAL,
        })
    }
  }

export function* companiesWatcher() {
    yield takeLatest(
        types.GETTING_COMPANIES,
        getCompanies
    );
}
