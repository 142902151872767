import React, { useEffect, useRef } from "react";
import Util from "../../utils/utils";
import "./CustomerInfoScreen.scss";
import Button from "../../components/Button";
import ContactInput from "./components/ContactInput";
import colors from "../../utils/colors";
import ZaloPaySDK from "../../utils/zalopay";
import { useDispatch, useSelector } from "react-redux";
import { SafeAreaView } from "../../components/SafeAreaView";
import { clearErrMess, createOrder, createOrderHome, createPolicyDraft } from "../../stores/payment/payment.action";
import { ERROR_MESSAGES, UIC_PRODUCT_CODES, VNI_PRODUCT_CODES } from "../../utils/const";
import { useHistory, useLocation } from "react-router-dom";
import { InsuranceSummary } from "../../components/InsuranceSummary/InsuranceSummary";
import { getCities } from "../../stores/address/address.action";
import MotobikeInput from "./components/MotobikeInput";
import MotobikeInputRecipient from "./components/MotobikeInputRecipient";
import { TYPE_INSURANCE, PATH } from "../../utils/const";
import { clearCheckPayment } from "../../stores/payment/payment.action";
import trackingId from "../../utils/tracking";
import CarInput from "./components/CarInput";
import CarInputRecipient from "./components/CarInputRecipient";
import Utils from "../../utils/utils";
import HomeInput from "./components/HomeInput";

const dataConsentPayment = [{
  "accident": [
    "Họ và tên của Bên mua bảo hiểm/  Người được bảo hiểm",
    "Số điện thoại",
    "Số CMND/ CCCD",
    "Ngày sinh",
    "Email",
    "Địa chỉ"
  ]
}, {
  "motobike": [
    "Họ và tên của chủ xe",
    "Biển số xe",
    "Số khung",
    "Số máy",
    "Họ tên của Bên mua bảo hiểm",
    "Số điện thoại",
    "Email",
    "Địa chỉ",
  ]
}, {
  "car": [
    "Họ và tên của chủ xe",
    "Biển số xe",
    "Hãng xe",
    "Hiệu xe",
    "Số khung",
    "Số máy",
    "Họ tên của Bên mua bảo hiểm",
    "Số điện thoại",
    "Email",
    "Địa chỉ",
  ]
}, {
  "home": [
    "Họ tên Người mua bảo hiểm",
    "Số CMND/CCCD/Hộ chiếu",
    "Địa chỉ ngôi nhà",
    "Số tầng",
    "Diện tích",
    "Họ tên của Bên mua bảo hiểm",
    "Số điện thoại",
    "Email",
    "Địa chỉ",
  ]
}, {
  "home-embed": [
    "Họ tên Người mua bảo hiểm",
    "Số CMND/CCCD/Hộ chiếu",
    "Địa chỉ ngôi nhà",
    "Số tầng",
    "Diện tích",
    "Họ tên của Bên mua bảo hiểm",
    "Số điện thoại",
    "Email",
    "Địa chỉ",
  ]
}];

const CustomerInfoScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    responseCreateOrder,
    insurance,
    createOrderData,
    creatingOrder,
    createOrderErrMsg,
    hasPayment,
  } = useSelector((state) => state.payment);
  const {
    insuranceDetail,
    type,
    logo,
    title,
    companyName,
    totalAllSumAssured,
    amount,
    timeText,
    typeHome,
    ownerShip,
    numberOfYearsUsed
  } = useLocation().state;

  const contactRef = useRef();
  const vehicleRef = useRef();
  const recipientRef = useRef();

  const houseRef = useRef();

  useEffect(() => {
    ZaloPaySDK.setTitle("Thông tin khách hàng");
    ZaloPaySDK.tracking(trackingId.CONTRACT_INFO.LOAD_PAGE_SUCCESSFULLY);
    ZaloPaySDK.tracking(trackingId.CONTRACT_INFO.USE_HOLDER_INFO);
  }, []);

  useEffect(() => {
    if (creatingOrder) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [creatingOrder]);

  useEffect(() => {
    if (createOrderErrMsg) {
      const errMsg = type === TYPE_INSURANCE.CAR ? createOrderErrMsg : ERROR_MESSAGES.NORMAL
      ZaloPaySDK.showDialog({
        message: errMsg,
        buttons: [{ text: "Đóng" }],
      });
      return () => {
        dispatch(clearErrMess());
      }
    }
  }, [createOrderErrMsg]);

  useEffect(() => {
    if (responseCreateOrder && createOrderData && insurance && hasPayment) {
      dispatch(clearCheckPayment());
      history.push({
        pathname: `${PATH}/contract-review/${responseCreateOrder.quotation_code}`,
        search: "subview=cashier"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCreateOrder, createOrderData, hasPayment]);

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  const onNextStepPress = () => {
    ZaloPaySDK.tracking(trackingId.CONTRACT_INFO.PAYMENT);
    const contract = getCustomerInfo(contactRef);
    if (!UIC_PRODUCT_CODES.includes(insuranceDetail.productCode)) {
      delete contract.dobEffect;
      delete contract.identity;
    }
    const isValidContract = contactValid(contactRef);
    if (type === TYPE_INSURANCE.MOTOBIKE || type === TYPE_INSURANCE.CAR) {
      const vehicleInfo = getVehicleInfo(vehicleRef);
      const isValidVehicleInfo = validVehicleInfo(vehicleRef);
      const recipient = getInsuranceRecipient(recipientRef);
      const isValidRecipient = validRecipient(recipientRef);
      if (isValidContract && isValidVehicleInfo && isValidRecipient) {
        const key = type === TYPE_INSURANCE.CAR ? "_convertLicensePlatesCar" : "_convertLicensePlates";
        vehicleInfo.licensePlates = Utils[key](vehicleInfo.licensePlates);
        dispatch(
          createOrder(type, insuranceDetail, contract, vehicleInfo, recipient)
        );
      }
    } else if(type == TYPE_INSURANCE.HOME || type === TYPE_INSURANCE.HOMEEMBED) {
      const homeInfo = getHomeInfo(houseRef);
      const isValidHomeInfo = validHomeInfo(houseRef);
      const recipient = {
        fullNameRecipient: "",
        phoneRecipient: "",
        addressRecipient: "",
        cityRecipient: {},
        districtRecipient: {},
        wardRecipient: {},
      }
      if(isValidContract && isValidHomeInfo) {
        dispatch(
          createOrderHome(type, {...insuranceDetail, typeHome, ownerShip}, contract, homeInfo, recipient)
        );
      }
    } else {
      if (isValidContract) {
        dispatch(createOrder(type, insuranceDetail, contract));
      }
    }
  };

  const handleCreatePolicyDraft = (interval) => {
    const isValidVehicleInfo = validVehicleInfoByPhone(vehicleRef);
    const isValidContract = contactValidByPhone(contactRef);
    const contract = getCustomerInfo(contactRef);
    const vehicleInfo = getVehicleInfo(vehicleRef);

    if (isValidContract && isValidVehicleInfo) {
      const key = type === TYPE_INSURANCE.CAR ? "_convertLicensePlatesCar" : "_convertLicensePlates";
      vehicleInfo.licensePlates = Utils[key](vehicleInfo.licensePlates);
      dispatch(
        createPolicyDraft(type, insuranceDetail, contract, vehicleInfo)
      );
      // console.log(insuranceDetail, contract, vehicleInfo);
      clearInterval(interval);
    }
  }

  const onBlurPhone = () => {
    const interval = setInterval(() => {
      handleCreatePolicyDraft(interval)
    }, 1000);
  }

  return (
    <div>
      <SafeAreaView id="wrapper-scroll" style={styles}>
        <div style={{ paddingBottom: 10 }}>
          {insuranceDetail && (
            <div className="margin-bottom-16">
              <InsuranceSummary
                logo={logo}
                title={title}
                companyName={companyName}
                totalAllSumAssured={totalAllSumAssured}
                amount={amount}
                timeText={timeText}
                type={type}
              />
            </div>
          )}

          <div id="contact" className="form">
            {type === TYPE_INSURANCE.MOTOBIKE ? (
              <MotobikeInput ref={vehicleRef} productCode={insuranceDetail.productCode} />
            ) : type === TYPE_INSURANCE.CAR ? <CarInput ref={vehicleRef} productCode={insuranceDetail.productCode} /> : (type === TYPE_INSURANCE.HOME || type === TYPE_INSURANCE.HOMEEMBED) ? <HomeInput ref={houseRef} productCode={insuranceDetail.productCode} /> : null}
            <ContactInput
              ref={contactRef}
              minDateEffect={insuranceDetail.effectiveDate}
              productCode={insuranceDetail.productCode}
              minAge={insuranceDetail.eligibility && insuranceDetail.eligibility.min_age}
              maxAge={insuranceDetail.eligibility && insuranceDetail.eligibility.max_age}
              insuranceDetail={insuranceDetail}
              onBlurPhone={onBlurPhone}
            />
            <div className="form_row">
              <div className="w-100 font-size-12">
                <strong>Bằng việc tiếp tục thanh toán, bạn đồng ý với Chính sách Quyền riêng tư và cho phép Zalopay & đối tác Savemoney chia sẻ thông tin cho đối tác bảo hiểm để thực hiện hợp đồng bảo hiểm:</strong>
                <ul style={{marginLeft: -10, marginTop: 5}}>
                  {
                    dataConsentPayment.find(f => f[type])[type].map((m, i) => (
                      <li style={{margin: "3px 0px"}} key={i}>{m}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
            {type === TYPE_INSURANCE.MOTOBIKE ? (
              <MotobikeInputRecipient ref={recipientRef} />
            ) : type === TYPE_INSURANCE.CAR ? <CarInputRecipient ref={recipientRef} /> : (type === TYPE_INSURANCE.HOME || type === TYPE_INSURANCE.HOMEEMBED) ? <div className="form_row">
              <div className="w-100 font-size-12 color-gray margin-bottom-16">
                Lưu ý: Giấy chứng nhận bảo hiểm điện tử được cấp qua email hoặc tin nhắn SMS.
              </div>
            </div> : null}
          </div>
        </div>
      </SafeAreaView>
      <div className="button-fixed-bottom">
        <div className="price">{Util.formatMoneyVND(amount)}đ</div>
        <Button
          className="btn-main"
          onPress={onNextStepPress}
          style={{ backgroundColor: colors.main }}
        >
          Tiếp tục
        </Button>
      </div>
    </div>
  );
};

const styles = {
  height: "calc(100vh - env(safe-area-inset-bottom) - 76px)",
  bottom: 76,
  position: "fixed",
  top: 0,
  overflowY: 'auto'
};

export default CustomerInfoScreen;

const contactValid = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const contactValidByPhone = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfoByPhone();
  }
};

const getCustomerInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getCustomerInfo();
  }
};

const validVehicleInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const validVehicleInfoByPhone = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfoByPhone();
  }
};

const getVehicleInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getVehicleInfo();
  }
};

const getHomeInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getHomeInfo();
  }
};

const validHomeInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const validRecipient = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const getInsuranceRecipient = (ref) => {
  if (ref && ref.current) {
    return ref.current.getInsuranceRecipient();
  }
  return null;
};
