export const types = {
  GETTING_CONTRACT_LIST: "GETTING_CONTRACT_LIST",
  GET_CONTRACT_LIST_SUCCESS: "GET_CONTRACT_LIST_SUCCESS",
  GET_CONTRACT_LIST_FAIL: "GET_CONTRACT_LIST_FAIL",
  GETTING_CONTRACT_DETAIL: "GETTING_CONTRACT_DETAIL",
  GET_CONTRACT_DETAIL_SUCCESS: "GET_CONTRACT_DETAIL_SUCCESS",
  GET_CONTRACT_DETAIL_FAIL: "GET_CONTRACT_DETAIL_FAIL",
  GETTING_INSURANCE_EMBED_LIST: "GETTING_INSURANCE_EMBED_LIST",
  GET_INSURANCE_EMBED_LIST_SUCCESS: "GET_INSURANCE_EMBED_LIST_SUCCESS",
  GET_INSURANCE_EMBED_LIST_FAIL: "GET_INSURANCE_EMBED_LIST_FAIL",

};
