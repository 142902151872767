import React from "react";
import Modal from "react-modal";
import Button from "../../../components/Button";
import colors from "../../../utils/colors";
import images from "../../../utils/images";
import "./ModalFilter.scss";
import { FILTER_CONTRACT } from "../../../utils/const";

export const listFilter = [
  {
    label: "Tất cả bảo hiểm",
    value: FILTER_CONTRACT.ALL,
  },
  {
    label: "Bảo hiểm tai nạn",
    value: FILTER_CONTRACT.ACCIDENT,
  },
  {
    label: "Bảo hiểm xe máy",
    value: FILTER_CONTRACT.MOTOBIKE,
  },
  {
    label: "Bảo hiểm xe ô tô",
    value: FILTER_CONTRACT.CAR,
  },
];

const ModalFilter = (props) => {
  const { isOpen, afterOpenModal, closeModal, filterValue, onChange } = props;
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="address-input-title">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="address-input-title-text">Loại bảo hiểm</div>
        </div>
        <Button
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "transparent",
          }}
          onPress={closeModal}
        >
          <div style={{ color: colors.main, padding: "10px 16px 0px" }}>
            <img src={images.icon_close} alt="icon clear" />
          </div>
        </Button>
      </div>
      <div
        className="margin-h-16 margin-bottom-16 border-radius-4"
        style={{ overflow: "hidden" }}
      >
        {listFilter.map((item, index) => (
          <div
            className="bg-white flex-v-center flex-space-between modal-item"
            key={index}
            onClick={() => onChange(item.value)}
          >
            <div
              className={`${
                filterValue === item.value ? "color-blue" : "color-black"
              } font-size-14`}
            >
              {item.label}
            </div>
            {filterValue === item.value ? (
              <div className="modal-icon">
                <img
                  src={images.icon_systems_other_check_done}
                  alt="icon_systems"
                />
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </Modal>
  );
};

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(36, 39, 43, 0.5)",
    zIndex: 3,
  },
  content: {
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: 0,
    padding: 0,
    width: "100%",
    border: "none",
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    background: colors.background,
  },
};

export default React.memo(ModalFilter);
