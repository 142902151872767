import {types} from './company.type';

const initState = {
  companies: [],
};

export const companiesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.companies,
      };
    default:
      return state;
  }
};
