import FloatInput from "components/FloatInput/FloatInput";
import React, {
    useState,
    useImperativeHandle,
    forwardRef,
    useRef,
    useEffect,
    useCallback,
    Fragment,
} from "react";
import Util from "../../../utils/utils";
import { ABIC_PRODUCT_CODES } from "utils/const";
import ChooseAddress from "./ChooseAddress";
import { scrollToRefObject } from "utils/helper";

const HomeInput = forwardRef((props, ref) => {
    const { productCode } = props;

    const refHomeOwner = useRef();
    const refIdentity = useRef();
    const refPhone = useRef();
    const refAddress = useRef();
    const refNameProject = useRef();
    const refFloor = useRef();
    const refAcreage = useRef();

    const [homeOwner, setHomeOwner] = useState("");
    const [isValidHomeOwner, setIsValidHomeOwner] = useState(true);

    const [identity, setIdentity] = useState("");
    const [isValidIdentity, setIsValidIdentity] = useState(true);

    const [phone, setPhone] = useState("");
    const [isValidPhone, setIsValidPhone] = useState(true);
    
    const [city, setCity] = useState({});
    const [district, setDistrict] = useState({});
    const [ward, setWard] = useState({});
    const [refChooseAddress, setRefChooseAddress] = useState(null);
    const [isValidCity, setIsValidCity] = useState(true);
    const [isValidDistrict, setIsValidDistrict] = useState(true);
    const [isValidWard, setIsValidWard] = useState(true);

    const [address, setAddress] = useState("");
    const [isValidAddress, setIsValidAddress] = useState(true);

    const [nameProject, setNameProject] = useState("");

    const [floor, setFloor] = useState("");
    const [isValidFloor, setIsValidFloor] = useState(true);

    const [acreage, setAcreage] = useState("");
    const [isValidAcreage, setIsValidAcreage] = useState(true);

    const onNameChange = (value) => {
        const nameValue = Util.toTitleCase(value);
        const valid = Util.validateName(nameValue);
        setIsValidHomeOwner(valid);
        const name = ABIC_PRODUCT_CODES.includes(productCode) ? nameValue.toUpperCase() : nameValue;
        setHomeOwner(name);
    };

    const onIdentityChange = (value) => {
        const valid = Util.validateIdentity(value);
        setIsValidIdentity(valid);
        setIdentity(value);
    }

    const onPhoneChange = (value) => {
        let valid = Util.validatePhone(value);
        setIsValidPhone(valid);
        setPhone(value);
    }
    
    const onChooseAddressChange = (value, refChooseAddress) => {
        setRefChooseAddress(refChooseAddress);
        setIsValidCity(!!value.city);
        setCity(value.city ? value.city : "");
    
        setIsValidDistrict(!!value.district);
        setDistrict(value.district ? value.district : "");
    
        setIsValidWard(!!value.ward);
        setWard(value.ward ? value.ward : "");
    };

    const onAddressChange = (value) => {
        let valid = Util.validateAddress(value);
        setIsValidAddress(valid);
        setAddress(value);
    }

    const onNameProject = (value) => {
        setNameProject(value);
    }

    const onFloorChange = (value) => {
        setFloor(value)
        setIsValidFloor(!!value);
    }

    const onfAcreageChange = (value) => {
        setAcreage(value)
        setIsValidAcreage(!!value);
    }

    const getHomeInfo = () => {
        return {
            homeOwner,
            identity,
            phone,
            city, district, ward,
            address,
            nameProject,
            floor,
            acreage
        };
    };

    useImperativeHandle(ref, () => ({
        validateInfo() {
            let validHomeOwner = Util.validateEmptyText(homeOwner);
            setIsValidHomeOwner(validHomeOwner);
            let validIdentiy = Util.validateEmptyText(identity);
            setIsValidIdentity(validIdentiy);
            let validPhone = Util.validateEmptyText(phone);
            setIsValidPhone(validPhone);
            let validCity = Util.validateEmptyText(city.code);
            setIsValidCity(validCity);
            let validDistrict = Util.validateEmptyText(district.code);
            setIsValidDistrict(validDistrict);
            let validWard = Util.validateEmptyText(ward.code);
            setIsValidWard(validWard);
            let validAddress = Util.validateEmptyText(address);
            setIsValidAddress(validAddress);
            let validFloor = Util.validateEmptyText(floor);
            setIsValidFloor(validFloor);
            let validAcreage = Util.validateEmptyText(acreage);
            setIsValidAcreage(validAcreage);
            if(!validAcreage) {
                scrollToRefObject(refAcreage)
            }
            if(!validFloor) {
                scrollToRefObject(refFloor)
            }
            if(!validAddress) {
                scrollToRefObject(refAddress)
            }
            if(!validWard) {
                scrollToRefObject(refChooseAddress)
            }
            if(!validPhone) {
                scrollToRefObject(refPhone)
            }
            if(!validIdentiy) {
                scrollToRefObject(refIdentity)
            }
            if(!validHomeOwner) {
                scrollToRefObject(refHomeOwner)
            }
            return validHomeOwner && validIdentiy && validPhone && validCity && validDistrict && validWard && validAddress && validFloor && validAcreage;
        },
        getHomeInfo() {
          return getHomeInfo();
        },
      }));

    return <Fragment>
        <div className="form_title">
            <div>Thông tin nhà</div>
        </div>
        <div className="form_row" ref={refHomeOwner}>
            <FloatInput
                ref={refHomeOwner}
                id="refHomeOwner"
                label={isValidHomeOwner ? "Họ và tên người mua bảo hiểm" : Util.invalidInfo("fullName")}
                value={homeOwner}
                iconClear={true}
                textTransform={"capitalize"}
                error={!isValidHomeOwner}
                onChangeText={onNameChange}
                require
            />
        </div>
        <div className="form_row" ref={refIdentity}>
            <FloatInput
                type="number"
                ref={refIdentity}
                id="refIdentity"
                label={isValidIdentity ? "Số CCCD/CMND/Hộ chiếu" : Util.invalidInfo("identity")}
                value={identity}
                iconClear={true}
                error={!isValidIdentity}
                onChangeText={onIdentityChange}
                require
            />
        </div>
        <div className="form_row" ref={refPhone}>
          <FloatInput
                ref={refPhone}
                id="refPhone"
                type="tel"
                label={isValidPhone ? "Số điện thoại" : Util.invalidInfo("phone")}
                value={phone}
                iconClear={true}
                error={!isValidPhone}
                onChangeText={onPhoneChange}
                require
          />
        </div>
        <div ref={refChooseAddress} className="w-100">
            <ChooseAddress
                data={{ city, district, ward }}
                valid={{ isValidCity, isValidDistrict, isValidWard }}
                onchange={onChooseAddressChange}
                refInput={refChooseAddress}
            />
        </div>
        <div className="form_row" ref={refAddress}>
            <FloatInput
                ref={refAddress}
                label={isValidAddress ? "Địa chỉ ngôi nhà" : Util.invalidInfo("address")}
                value={address}
                iconClear={true}
                error={!isValidAddress}
                onChangeText={onAddressChange}
                require
            />
        </div>
        <div className="form_row" ref={refNameProject}>
            <FloatInput
                ref={refNameProject}
                label={"Tên chung cư / dự án (nếu có)"}
                value={nameProject}
                iconClear={true}
                onChangeText={onNameProject}
            />
        </div>
        <div className="form_row" ref={refFloor}>
            <FloatInput
                ref={refFloor}
                type="number"
                label={isValidFloor ? "Số tầng" : "Vui lòng kiểm tra số tầng"}
                value={floor}
                iconClear={true}
                onChangeText={onFloorChange}
                error={!isValidFloor}
                require
            />
        </div>
        <div className="form_row" ref={refAcreage}>
            <FloatInput
                ref={refAcreage}
                type="number"
                label={isValidAcreage ? "Diện tích (m2)" : "Vui lòng kiểm tra diện tích"}
                value={acreage}
                iconClear={true}
                onChangeText={onfAcreageChange}
                error={!isValidAcreage}
                require
            />
        </div>
    </Fragment>
});

export default React.memo(HomeInput);