import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import images from '../../utils/images';
import './Footer.scss';
import {PATH, TYPE_CONTRACT} from "../../utils/const";
import ZaloPaySDK from "../../utils/zalopay";
import trackingId from '../../utils/tracking';
import utils from '../../utils/utils';

export const Footer = withRouter(() => {
    const [isCopy, setIsCopy] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [pathUrl, setPathUrl] = useState("");
    const history = useHistory();

    const { param, param_share_link } = useSelector((state) => state.insurance);

    useEffect(() => {
      const path = process.env.REACT_APP_ENVIRONMENT === "production" ? "https://social.zalopay.vn/spa/v2/apps-v2/sm-insurance" : "https://socialdev.zalopay.vn/spa/v2/apps-v2/sm-insurance";
      setPathUrl(`${path}${history.location.pathname}${param_share_link ? `?product_type=${param_share_link}`: ""}`);
    }, [window.location.pathname, param_share_link]);

    const goToScreen = (path) => {
      const url = (param && path === "home") ? `${PATH}/${path}?product_type=${param}` : `${PATH}/${path}`
      utils.trackingBeforeRedirect(
        () => history.push(url),
        () =>
          ZaloPaySDK.tracking(
            path === "home"
              ? trackingId.CONTRACT_MANAGEMENT.HOME
              : trackingId.INSURANCE_LISTING.MANAGE_CONTRACT
          )
      );
    };

    const handleShareLink = () => {
      const textToCopy = pathUrl;
      const tempInput = document.createElement("textarea");
      tempInput.value = textToCopy;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      setIsCopy(true)
    }

    return (
        <div className='border-top-1 pos-fixed flex row bg-white footer-home-page'>
            <div onClick={()=>{goToScreen("home")}} className={`${window.location.pathname === `${PATH}/home` ? 'color-blue disabled' : 'color-gray'} padding-v-4 flex-center flex-column w-50`}>
                <img alt='' src={window.location.pathname === `${PATH}/home` ? images.icon_home_active : images.icon_home_inactive} />
                <div className='font font-size-11 line-height-12'>Trang chủ</div>
            </div>
            <div onClick={() => setIsOpenModal(true)} className='padding-v-4 flex-center flex-column w-50 color-gray'>
                <img alt='' src={images.icon_share_link} />
                <div className='font font-size-11 line-height-12'>Chia sẽ link</div>
            </div>
            <div onClick={()=>{goToScreen(`management?payment=${TYPE_CONTRACT.PAID}`)}} className={`${window.location.pathname.includes(`${PATH}/management`) ? 'color-blue disabled' : 'color-gray'} padding-v-4 flex-center flex-column w-50`}>
                <img alt='' src={window.location.pathname.includes(`${PATH}/management`) ? images.icon_contract_active : images.icon_contract_inactive} />
                <div className='font font-size-11 line-height-12'>Hợp đồng</div>
            </div>
            <div onClick={()=>{goToScreen("search")}} className={`${window.location.pathname.includes(`${PATH}/search`) ? 'color-blue disabled' : 'color-gray'} padding-v-4 flex-center flex-column w-50`}>
                <img alt='' src={window.location.pathname.includes(`${PATH}/search`) ? images.icon_search_active : images.icon_search} />
                <div className='font font-size-11 line-height-12'>Tra cứu</div>
            </div>

            <div className='modal-share-link' style={{display: isOpenModal ? "block" : "none"}}>
              <div className="modal-content">
                <div onClick={() => {
                  setIsOpenModal(false);
                  setIsCopy(false);
                }} className="close">&times;</div>
                <img className='banner' src={images.zalopay_banner_car_v2} alt='' />
                <div className="content">
                  <p className='title'><strong>Chia sẻ đường dẫn</strong></p>
                  <input className='input-custom' readOnly disabled value={pathUrl} id="input-share-link" />
                  <label>Hãy chia sẻ ngay đường dẫn cho bạn bè để biết thêm thông tin</label>
                  <div className='list-btn' style={{
                    gridTemplateColumns: `repeat(${isCopy ? "2" : "1"}, minmax(0, 1fr))`
                  }}>
                    <button onClick={handleShareLink} className='btn-copy'>
                      <img src={images.icon_copy} alt='' />
                      <p className='text'>Sao chép đường dẫn</p>
                    </button>
                    {
                      isCopy && <div className='confirm'>
                        <img src={images.icon_check} alt='' />
                        <p className='text'>Đã sao chép</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
        </div>
    )
})