export const types = {
  CREATING_ORDER: 'CREATING_ORDER',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAIL: 'CREATE_ORDER_FAIL',
  CLEAR_ORDER_DATA: 'CLEAR_ORDER_DATA',
  CREATING_PAY_ORDER: 'CREATING_PAY_ORDER',
  CREATE_PAY_ORDER_SUCCESS: 'CREATE_PAY_ORDER_SUCCESS',
  CREATE_PAY_ORDER_FAIL: 'CREATE_PAY_ORDER_FAIL',
  CLEAR_CHECK_PAYMENT: 'CLEAR_CHECK_PAYMENT',
  CLEAR_PAYMENT_INFO: 'CLEAR_PAYMENT_INFO',
  CLEAR_MESS_ERROR: 'CLEAR_MESS_ERROR',
  CREATING_POLICY_DRAFT: 'CREATING_POLICY_DRAFT'
}
