export const dataHouse = {
    "data": {
      "getHomeInsuranceAllProduct": {
        "products": [
          {
              "id": 260,
              "productId": "53c3b62a-0b1a-4f30-a6c4-e6c09ca22e40",
              "productCode": "ABIC-HOME4",
              "company": {
                "id": 47,
                "code": "ABIC",
                "name": "CÔNG TY CP BẢO HIỂM NGÂN HÀNG NÔNG NGHIỆP",
                "address": "Tầng 6 - Tòa nhà 29T1 - Hoàng Đạo Thúy - Trung Hòa - Cầu Giấy - Hà Nội",
                "phoneNumber": "(84.24) 6299.0055",
                "image": "https://uat2-zalopay.savemoney.vn/assets/upload/company/abic20230627083001.png",
                "__typename": "Company"
              },
              "discountRate": 0,
              "name": "Bảo hiểm nhà - Gói Toàn Diện",
              "effectiveDate": 0,
              "policyTerm": 12,
              "description": "Bảo hiểm nhà - Gói Toàn Diện",
              "type": 1,
              "image": "https://uat2-zalopay.savemoney.vn/assets/upload/company/abic20230627083001.png",
              "premium": 132000,
              "insuredDeclaredValue": "<ul><li><span style=\"color: rgb(34, 34, 34); font-family: Roboto, sans-serif;\">Chủ xe ô tô tham gia giao thông trên lãnh thổ nước Cộng hòa xã hội chủ nghĩa Việt Nam.</span></li></ul>",
              "cashlessGarage": "",
              "listBenefit": "147,149",
              "advanceCash": "<div>Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới bồi thường trong những trường hợp sau:</div><ul><li><span style=\"color: rgb(0, 0, 0); font-size: 0.875rem;\">Thiệt hại ngoài hợp đồng về sức khỏe, tính mạng và tài sản đối với bên thứ ba do xe cơ giới gây ra.</span><br></li><li><span style=\"color: rgb(0, 0, 0); font-size: 0.875rem;\">Thiệt hại về sức khỏe và tính mạng của hành khách do xe cơ giới gây ra.</span></li></ul><p><span style=\"color: rgb(0, 0, 0);\">Bảo hiểm tai nạn người ngồi trên xe ô tô của TASCO chi trả trong trường hợp lái xe, phụ xe và những người ngồi trên xe ô tô không may gặp phải thiệt hại thân thể khi đang ở trên xe, lên xuống xe trong quá trình xe ô tô tham gia giao thông.</span></p><p><span style=\"color: rgb(0, 0, 0);\">(*) TASCO chỉ có trách nhiệm bồi thường khi khách hàng có lựa chọn số tiền bảo hiểm bảo hiểm tai nạn lái xe và người ngồi trên xe. Việc lựa chọn số tiền bảo hiểm Tai nạn lái xe và người ngồi trên xe đồng nghĩa với việc khách hàng tự nguyện chọn mua bảo hiểm tai nạn Lái xe, phụ xe và người người được chở trên xe và phí bảo hiểm sẽ được bao gồm trong tổng phí bảo hiểm khách hàng phải thanh toán.</span><br></p>",
              "zeroDepClaims": "<div>1. Tôi chấp nhận, đọc hiểu rõ tất cả&nbsp;<a href=\"https://datafiles.chinhphu.vn/cpp/files/vbpq/2023/9/67-cp.signed.pdf\" target=\"_blank\" style=\"color: rgb(0, 0, 255);\">quy tắc bảo hiểm</a>&nbsp;của sản phẩm bảo hiểm;</div><div>2. Đơn bảo hiểm là cơ sở của Hợp đồng giữa tôi và bảo hiểm và tôi sẽ chấp thuận tất cả các điều khoản quy định trong Hợp đồng bảo hiểm và các Quy định đối với loại bảo hiểm đề nghị mua của TASCO;</div><div>3. Tôi xác nhận và hoàn toàn chịu trách nhiệm về tính chính xác, trung thực, đầy đủ của tất cả các thông tin cung cấp nêu trên;</div><div>4. Bằng việc chọn Thanh toán, bạn đồng ý với Chính sách Quyền riêng tư và cho phép ZaloPay chia sẻ thông tin với Nhà cung cấp và các đối tác liên quan.</div>",
              "zeroDepreciation": "",
              "addons": "",
              "includedAddons": "",
              "sellingPoint": "<ul><li>Chi trả cho bên thứ ba lên đến 150 triệu đồng/ người/ vụ đối với thiệt hại về người, lên đến 100 triệu đồng/ vụ đối với thiệt hại về tài sản,</li><li>Thủ tục mua và bồi thường đơn giản,</li><li>Giấy chứng nhận bảo hiểm điện tử được gửi đến khách hàng ngay sau khi thanh toán thành công.</li></ul>",
              "claimDocs": "<ul><li>Gọi ngay đến số hotline của TASCO (được in trên giấy chứng nhận bảo hiểm) để được hỗ trợ và hướng dẫn</li><li>Thông báo ngay cho cơ quan công an hoặc chính quyền địa phương trong trường hợp tai nạn nghiêm trọng&nbsp;&nbsp;</li></ul>",
              "benefitsDescription": "<div>Mức trách nhiệm bảo hiểm tối đa TASCO chi trả đối với thiệt hại về sức khỏe, tính mạng, tài sản của bên thứ ba và hành khách do xe cơ giới gây ra trong mỗi vụ tai nạn thuộc phạm vi bảo hiểm như sau:</div><ul><li>Về người: 150 triệu đồng/ người/ vụ tai nạn;</li><li>Về tài sản: 100 triệu đồng/ vụ tai nạn đối với thiệt hại do xe ô tô gây ra.</li></ul><p>Mức chi trả tối đa với Lái, phụ xe và hành khách trên xe theo Số tiền bảo hiểm đã lựa chọn.</p>",
              "descriptionDetail": "https://baohiemtasco.vn/",
              "termCondition": "https://datafiles.chinhphu.vn/cpp/files/vbpq/2023/9/67-cp.signed.pdf",
              "idv": 320000000,
              "tenantId": "f73ad1c0-c0c5-4bf6-8e31-290e464f16b0",
              "benefits": [
                  {
                      "id": 242,
                      "parentId": 239,
                      "isActive": true,
                      "isDelete": false,
                      "benefitCode": "House-BR",
                      "type": 1,
                      "level": 1,
                      "data": null,
                      "createdAt": "2022-01-25T00:29:03.000Z",
                      "updatedAt": "2022-05-14T08:26:16.000Z",
                      "name": "Rủi ro cơ bản (Cháy, sét, nổ) đối với ngôi nhà",
                      "description": "nothing"
                  },
                  {
                      "id": 246,
                      "parentId": 239,
                      "isActive": true,
                      "isDelete": false,
                      "benefitCode": "House-MR1",
                      "type": 1,
                      "level": 2,
                      "data": null,
                      "createdAt": "2020-12-16T09:56:10.000Z",
                      "updatedAt": "2020-12-16T09:56:10.000Z",
                      "name": "Giông, bão, lũ lụt, tràn nước, va chạm với ngôi nhà, trộm cướp",
                      "description": "nothing"
                  },
                  {
                      "id": 247,
                      "parentId": 239,
                      "isActive": true,
                      "isDelete": false,
                      "benefitCode": "House-MR2",
                      "type": 1,
                      "level": 2,
                      "data": null,
                      "createdAt": "2020-12-16T09:56:10.000Z",
                      "updatedAt": "2020-12-16T09:56:10.000Z",
                      "name": "Chi phí dọn dẹp hiện trường, chi phí chữa cháy, chi phí thuê nhà sau tổn thất",
                      "description": "nothing"
                  },
                  {
                      "id": 248,
                      "parentId": 239,
                      "isActive": true,
                      "isDelete": false,
                      "benefitCode": "House-MR3",
                      "type": 1,
                      "level": 2,
                      "data": null,
                      "createdAt": "2020-12-16T09:56:10.000Z",
                      "updatedAt": "2020-12-16T09:56:10.000Z",
                      "name": "Đất sụt, đất lở, đá lở hoặc các di chuyển khác của đất (không bao gồm động đất)",
                      "description": "nothing"
                  }
              ],
              "__typename": "ProductForCarInsuranceFee"
          },
        ],
        "__typename": "HumanInsuranceFeeResponse"
      }
    }
}