import {types} from './appparams.type';

const initState = {
    userInfo: {
        mUid: '',
        mAccessToken: '',
        zalopay_userid: '',
        displayName: '',
        avatarUrl: '', 
        oauthCode: '',
        verifierCode: '',
        user_name: '',
        user_phone: '',
        user_email: '',
        id_number: ''

    },
    environment: 'web',
};

export const appParamsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.UPDATE_USER_INFO:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    mUid: action.userInfo.muid,
                    mAccessToken: action.userInfo.maccesstoken,
                    zalopay_userid: action.userInfo.userid,
                    displayName: action.userInfo.displayname,
                    avatarUrl: action.userInfo.avatarurl,
                    user_name: action.userInfo.user_name,
                    user_phone: action.userInfo.user_phone,
                    user_email: action.userInfo.user_email,
                    id_number: action.userInfo.id_number || "",
                },
                // environment: action.userInfo.environment || 'web'
            };

        case types.GET_TOKEN_SUCCESS:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    token: action.token,
                },
            };
        case types.GET_OAUTH_CODE_SUCCESS:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    oauthCode: action.oauthInfo.oauthCode,
                    verifierCode: action.oauthInfo.verifierCode
                },
                environment: action.oauthInfo.environment || 'web'
            }
        default:
            return state;
    }
};