import { types } from "./payment.type";

const initState = {
  responseCreateOrder: null,
  createOrderData: null,
  insurance: null,
  creatingOrder: false,
  createOrderErrMsg: "",
  createPayOrder: false,
  hasPayment: false,
  isPayment: false,
  paymentInfo: null,
  createPayOrderErrMsg: "",
};

export const paymentReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CREATING_ORDER:
      return {
        ...state,
        creatingOrder: true,
        createOrderData: null,
        responseCreateOrder: null,
        insurance: null,
        createOrderErrMsg: "",
      };
    case types.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        creatingOrder: false,
        createOrderData: action.createOrderData,
        responseCreateOrder: action.responseCreateOrder,
        insurance: action.insurance,
        hasPayment: true,
      };
    case types.CREATE_ORDER_FAIL:
      return {
        ...state,
        creatingOrder: false,
        createOrderErrMsg: action.errMsg
      };

    case types.CREATING_PAY_ORDER:
      return {
        ...state,
        createPayOrder: true,
        paymentInfo: null,
        createPayOrderErrMsg: "",
      };

    case types.CREATE_PAY_ORDER_SUCCESS:
      return {
        ...state,
        createPayOrder: false,
        paymentInfo: action.paymentInfo,
        isPayment: true,
      };

    case types.CREATE_PAY_ORDER_FAIL:
      return {
        ...state,
        createPayOrder: false,
        createPayOrderErrMsg: action.errMsg,
      };
    
    case types.CLEAR_CHECK_PAYMENT:
      return {
        ...state,
        hasPayment: false,
      };

    case types.CLEAR_PAYMENT_INFO:
      return {
        ...state,
        isPayment: false,
      };

    case types.CLEAR_MESS_ERROR:
      return {
        ...state,
        createOrderErrMsg: "",
      }
    case types.CREATING_POLICY_DRAFT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
