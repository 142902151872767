import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ZaloPaySDK from "../../utils/zalopay";
import "./ContractDetailScreen.scss";
import InsuranceInfo from "./components/InsuranceInfo";
import ContractOwnerInfo from "../../screens/ContractReviewScreen/components/ContractOwnerInfo";
import RecipientInfo from "./components/RecipientInfo";
import MotobikeInfo from "../../screens/ContractReviewScreen/components/MotobikeInfo";
import { SafeAreaView } from "../../components/SafeAreaView";
import ModalCoverageBenefit from "./components/ModalCoverageBenefit";
import images from "../../utils/images";
import { PATH, APPID, ZPI_AGREEMENT_PAY, PRODUCT_TYPE, APPID_CAR } from "../../utils/const";
import trackingId from "../../utils/tracking";
import Utils from "../../utils/utils";
import { getContractDetail } from "../../stores/contract/contract.action";
import ModalUnBinding from "../../components/Modal/ModalUnBinding";
import {
  createAgreementpay,
  clearAgreementpay,
  unBindingAgreementpayAction,
} from "../../stores/agreementpay/agreementpay.action";
import { useQuery } from "screens/HomeScreen/HomeScreen";

const ContractDetailScreen = ({ type }) => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [
    isOpenModalUnBindingAgreement,
    setIsOpenModalUnBindingAgreement,
  ] = useState(false);
  const { quotationCode } = useParams();
  const queryMUID = useQuery().get('muid');
  const dataFromPaymentPage = useLocation().state;
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.appParams);
  const {
    contractDetail,
    gettingContractDetail,
    getContractDetailErrMsg,
  } = useSelector((state) => state.contract);

  const {
    creatingAgreementpay,
    agreementpayResponse,
    createAgreementpayErrMsg,
    unBindingAgreementpay,
    unBindingAgreementpaySuccess,
    unBindingAgreementpayErrMsg,
  } = useSelector((state) => state.agreementpay);

  const { param } = useSelector((state) => state.insurance);

  console.log(queryMUID, type)

  useEffect(() => {
    const unListen = history.listen((newLocation, action) => {
      if (action === "POP") {
        if(param) {
          history.push(`${PATH}/home?product_type=${param}`);
        }else {
          history.goBack();
        }
        // history.goBack();
      }
    });
    return () => {
      setTimeout(() => {
        unListen();
      }, 500);
    };
  }, [history]);

  useEffect(() => {
    if (agreementpayResponse) {
      dispatch(clearAgreementpay());
      if (ZaloPaySDK.isZaloPay()) {
        ZaloPaySDK.launchDeeplink(
          agreementpayResponse.requestBinding.deepLink,
          () => {}
        );
      } else {
        const appID = contractDetail ? [PRODUCT_TYPE.motorbike_liability, PRODUCT_TYPE.personal_accident].includes(contractDetail.productType) ? APPID : APPID_CAR : APPID;
        console.log("screen contract detail", appID);
        const queryParam = {
          appid: appID,
          binding_token: agreementpayResponse.requestBinding.bindingToken,
        };
        ZaloPaySDK.navigateTo(
          ZPI_AGREEMENT_PAY + new URLSearchParams(queryParam)
        );
      }
      if(param) {
        history.push(`${PATH}/home?product_type=${param}`);
      }else {
        history.push(`${PATH}/home`);
      }
      // history.push(`${PATH}/home`);
    }
  }, [agreementpayResponse, dispatch, history, quotationCode, userInfo.mUid, contractDetail]);

  useEffect(() => {
    if (unBindingAgreementpaySuccess) {
      if(type === "contract-detail") {
        dispatch(getContractDetail(quotationCode));
      }else if(type === "external-contract-detail") {
        dispatch(getContractDetail(quotationCode, "", queryMUID));
      }
    }
  }, [dispatch, quotationCode, unBindingAgreementpaySuccess, userInfo.mUid]);

  useEffect(() => {
    if(type === "contract-detail") {
      dispatch(
        getContractDetail(
          quotationCode,
          (dataFromPaymentPage && dataFromPaymentPage.fromPaymentPage) ? "waiting_for_approve" : ""
        )
      );
    }else if(type === "external-contract-detail") {
      dispatch(
        getContractDetail(
          quotationCode,
          (dataFromPaymentPage && dataFromPaymentPage.fromPaymentPage) ? "waiting_for_approve" : "", queryMUID
        )
      );
    }
  }, [dispatch, dataFromPaymentPage, quotationCode, userInfo.mUid]);

  useEffect(() => {
    if (
      gettingContractDetail ||
      creatingAgreementpay ||
      unBindingAgreementpay
    ) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [gettingContractDetail, creatingAgreementpay, unBindingAgreementpay]);

  useEffect(() => {
    if (getContractDetailErrMsg) {
      ZaloPaySDK.showDialog({
        message: getContractDetailErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
    if (createAgreementpayErrMsg) {
      ZaloPaySDK.showDialog({
        message: createAgreementpayErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
    if (unBindingAgreementpayErrMsg) {
      ZaloPaySDK.showDialog({
        message: unBindingAgreementpayErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
  }, [
    getContractDetailErrMsg,
    createAgreementpayErrMsg,
    unBindingAgreementpayErrMsg,
  ]);

  useEffect(() => {
    ZaloPaySDK.setTitle("Chi tiết hợp đồng");
    ZaloPaySDK.tracking(trackingId.SUCCESSFUL_CONTRACT.LOAD_PAGE_SUCCESSFULLY);
  }, []);

  const viewCoverageBenefit = () => {
    ZaloPaySDK.tracking(trackingId.SUCCESSFUL_CONTRACT.SEE_POLICY_DETAIL);
    setIsOpenModal(true);
  };

  const viewDetailBinding = () => {
    if (ZaloPaySDK.isZaloPay()) {
      ZaloPaySDK.launchDeeplink(process.env.REACT_APP_ZPA_AGREEMENT_PAY_LIST);
    } else {
      ZaloPaySDK.navigateTo(process.env.REACT_APP_ZPI_AGREEMENT_PAY_LIST);
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };
  const goToQAScreen = () => {
    Utils.trackingBeforeRedirect(
      () => ZaloPaySDK.tracking(trackingId.SUCCESSFUL_CONTRACT.FAQ),
      () => history.push(`${PATH}/qa`, { productCode: contractDetail.productCode })
    );
  };

  const goToManagementScreen = () => {
    if(param) {
      history.push(`${PATH}/home?product_type=${param}`);
    }else {
      history.push(`${PATH}/home`);
      // history.goBack();
    }
  };

  const renderContent = () => {
    if (contractDetail) {
      const isMotobike = contractDetail.productType === PRODUCT_TYPE.motorbike_liability;
      const isCar = contractDetail.productType === PRODUCT_TYPE.car;
      const isHome = contractDetail.productType === PRODUCT_TYPE.home;
      const latestEndorsementApproved = contractDetail.endorsement
        .find(
          (endorsement) =>
            endorsement.status === "approved" ||
            endorsement.status === "waiting_for_approve"
        );
      const isHaveRenew =
        moment(
          latestEndorsementApproved
            ? latestEndorsementApproved.IMExpiryDate
            : contractDetail.insuredMemberDetail[0].expired_date
        ).diff(moment(), "days") > 2 && contractDetail.status === "approved";
      const isRenewed =
        contractDetail.bindingStatus === "Confirmed" ||
        contractDetail.bindingStatus === "Disabled";
      return (
        <>
          <InsuranceInfo
            typeContract={type}
            contract={contractDetail}
            viewCoverageBenefit={viewCoverageBenefit}
            viewDetailBinding={viewDetailBinding}
            latestEndorsementApproved={latestEndorsementApproved}
          />
          <ModalCoverageBenefit
            contract={contractDetail}
            isOpen={isOpenModal}
            closeModal={closeModal}
          />
          <ContractOwnerInfo
            typeContract={type}
            fullName={contractDetail.holderName}
            phone={contractDetail.holderPhoneNumber}
            email={contractDetail.holderEmail}
            address={contractDetail.holderAddress}
            ward={contractDetail.holderWardNameWithType}
            district={contractDetail.holderDistrictNameWithType}
            city={contractDetail.holderCityProvinceNameWithType}
          />
          {(isMotobike || isCar || isHome) ? (
            <>
              {
                (isMotobike || isCar) && <MotobikeInfo
                  carOwner={
                    contractDetail.insuredMemberDetail[0].vehicleOwnerName
                  }
                  licensePlate={contractDetail.insuredMemberDetail[0].plateNumber}
                />
              }
              <RecipientInfo
                typeContract={type}
                contract={contractDetail}
                quotationCode={quotationCode}
                mUid={userInfo.mUid}
              />
            </>
          ) : null}
          <div
            className="bg-white padding-v-12 padding-h-16"
            onClick={goToQAScreen}
          >
            <div className="flex-v-center flex-space-between">
              <div className="flex-v-center">
                <div className="qa-icon-left">
                  <img
                    src={images.icon_systems_glyph_info_tutorial}
                    alt="icon_systems"
                  />
                </div>
                <div className="qa-title color-black font-size-14 margin-left-12">
                  Câu hỏi thường gặp
                </div>
              </div>
              <div className="qa-icon-right">
                <img
                  src={images.icon_systems_other_arrow_right}
                  alt="icon_systems"
                />
              </div>
            </div>
          </div>
          {isRenewed ? (
            <div
              className="color-blue margin-bottom-90 text-align-center margin-top-12 text-underline"
              onClick={() => setIsOpenModalUnBindingAgreement(true)}
            >
              Hủy gia hạn
            </div>
          ) : (
            <div className={`${type === "contract-detail" ? "margin-bottom-90" : ""}`}></div>
          )}

          {
            type === "contract-detail" && <div className="button-fixed-bottom">
              <button
                className={`btn-close ${
                  !isMotobike && !isRenewed && isHaveRenew
                    ? "btn-close-extend"
                    : ""
                }`}
                onClick={goToManagementScreen}
              >
                Đóng
              </button>
              {!isMotobike && !isRenewed && isHaveRenew && (
                <button
                  className="btn-extend"
                  onClick={() =>
                    dispatch(createAgreementpay(-1, contractDetail.quotationCode))
                  }
                >
                  Gia hạn tự động
                </button>
              )}
            </div>
          }
          <ModalUnBinding
            isOpen={isOpenModalUnBindingAgreement}
            closeModal={() => setIsOpenModalUnBindingAgreement(false)}
            unbinding={() => {
              dispatch(
                unBindingAgreementpayAction(
                  contractDetail.bindingTerm,
                  contractDetail.quotationCode
                )
              );
              setIsOpenModalUnBindingAgreement(false);
            }}
          />
        </>
      );
    } else {
      return null;
    }
  };

  return <SafeAreaView>{renderContent()}</SafeAreaView>;
};

export default ContractDetailScreen;
