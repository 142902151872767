import {put, takeLatest} from 'redux-saga/effects';
import {AppApiInstance} from '../../api/app-api';
import {types} from './appparams.type';
import {store} from './../configStore';
import {ERROR_MESSAGES} from "../../utils/const";
import ZaloPaySDK from "../../utils/zalopay";

function* getTokenUser({cb}) {
  const {userInfo} = store.getState().appParams;
  try {
    const data = yield AppApiInstance.verifyUser({muid: userInfo.mUid, maccesstoken: userInfo.mAccessToken});
    if (data && data.returncode === 1) {
      yield put({type: types.GET_TOKEN_SUCCESS, token: 'token'});
      cb(true);
    } else {
      ZaloPaySDK.showDialog({message: ERROR_MESSAGES.LOGIN_FAIL, buttons: [{text: 'Đóng'}]});
      cb(false);
    }
  } catch (err) {
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES.LOGIN_FAIL, buttons: [{text: 'Đóng'}]});
    cb(false);
  }
}

function* getOauthV1Code({cb}) {
  const { userInfo } = store.getState().appParams;
  try {
    const data = yield AppApiInstance.getUserInfo({oauthCode: userInfo.oauthCode, verifierCode: userInfo.verifierCode});
    // const data = yield AppApiInstance.getUserInfo({
    //   oauthCode: "ysBfwrI46FyJ0mbGW2x9ivGMpCqVw0rHZ7X85IRQWiMzbGUG9l3sR23HExGYHkgC",
    //   verifierCode: "Zewyt.rnS.CuNfDlM8u0ewzE6fJjDChXqj6ujjBaPnc"
    // }) // test local
    yield put({type: types.UPDATE_USER_INFO, userInfo: {
      muid: data.data.getUserInfo.data.muid,
      user_name: data.data.getUserInfo.data.user_name,
      user_phone: (data.data.getUserInfo.data.user_phone + "").replace("84", "0"),
      user_email: data.data.getUserInfo.data.user_email,
      id_number: "",
    }});
    cb(true);
  } catch (error) {
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES._LOGIN_FAIL, buttons: [{text: 'Đóng'}]});
    cb(false);
  }
}

export function* appParamsWatcher() {
  yield takeLatest(types.GETTING_TOKEN, getTokenUser);
  yield takeLatest(types.GET_OAUTH_CODE, getOauthV1Code);
}