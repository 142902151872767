import React, { Fragment, useEffect, useRef, useState } from 'react';
import "./HouseProduct.scss"
import { LIST_HOME_TYPE, PATH } from 'utils/const';
import images from 'utils/images';
import FloatInput from 'components/FloatInput/FloatInput';
import Util from "../../utils/utils";
import Button from 'components/Button';
import { dataHouse } from './mockup/HouseData';
import { billingTypeAdapter, getStringDuration } from 'utils/helper';
import colors from 'utils/colors';
import { useHistory } from "react-router-dom";
import { getInsuranceHomeProduct } from 'stores/insurance/insurance.action';
import { useDispatch, useSelector } from 'react-redux';
import ZaloPaySDK from 'utils/zalopay';

const ICON = {
    villa: images.icon_mdi_villa,
	apartment: images.icon_mdi_apartment,
	independent: images.icon_mdi_home,
	town: images.icon_mdi_townhouse
};

const homeInfo = {
    "villa": 2,
    "apartment": 1,
    "independent": 4,
    "town": 5
}

const LIST_OWNERSHIP = [{
    name: "Chủ sở hữu",
    value: "non_commercial" //LAMOT
}, {
    name: "Nhà thuê",
    value: "commercial" //THUENHA
}]

const HouseProduct = () => {
    const refMaketPrice = useRef();
    const refNumberOfYearsUsed = useRef();
    
    const [typeHouse, setTypeHouse] = useState("villa");
    const [ownerShip, setOwnerShip] = useState("non_commercial");
    const [numberOfYearsUsed, setNumberOfYearsUsed] = useState(1);
    const [validNumberOfYearsUsed, setValidNumberOfYearsUsed] = useState(false);
    const [marketPrice, setMarketPrice] = useState("100.000.000");
    const [validMarketPrice, setValidMarketPrice] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        gettingInsuranceHomeProduct,
        insuranceHomeProduct,
        getInsuranceHomeProductErrMsg,
    } = useSelector((state) => state.insurance);

    const onChangeTypeHouse = (key) => {
        setTypeHouse(key);
    }

    const handleChangeOwnerShip = (e) => {
        setOwnerShip(e.target.value);
    }

    const handleChangeNumberOfYearsUsed = (value) => {
        if(+value <= 0 || +value > 30) {
            setValidNumberOfYearsUsed(true)
        }else {
            setValidNumberOfYearsUsed(false)
        }
        setNumberOfYearsUsed(value);
        setIsSearch(false);
    }

    const handleChangePrice = (value) => {
        const valueConvert = Util.convertCurrency(value);
        let valid = Util.validateCurrency(value);
        setValidMarketPrice(!valid);
        const valueFormat = value.split(".").join("");
        if(value) {
            if(+valueFormat % 1000000 !== 0) {
                setValidMarketPrice(true);
            }else if(+valueFormat < 100000000 || +valueFormat > 5000000000) {
                setValidMarketPrice(true);
            }else {
                setValidMarketPrice(false);
            }
        }else {
            setValidMarketPrice(true);
        }
        setMarketPrice(valueConvert);
        setIsSearch(false);
    }

    const setLabelNumberOfYearsUsed = () => {
        let text = "Số năm đã sử dụng";
        if(!numberOfYearsUsed) {
            text = "Vui lòng nhập số năm đã sử dụng"
        }else if(numberOfYearsUsed <= 0) {
            text = "Tối thiểu 01 năm sử dụng"
        }else if(numberOfYearsUsed > 30) {
            text = "Tối đa 30 năm sử dụng"
        }
        return text;
    };

    const setLabelMaketPrice = () => {
        let text = "Giới hạn trách nhiệm (đ)";
        const valueFormat = marketPrice.split(".").join("");
        if(!marketPrice) {
            text = "Vui lòng nhập số tiền";
        }else if(+valueFormat < 100000000 || +valueFormat > 5000000000) {
            text = "Số tiền phải từ 100.000.000 -> 5.000.000.000";
        }else if(!Util.validateCurrency(marketPrice)) {
            text = "Số tiền không hợp lệ! Chỉ được nhập số";
        }else if(+valueFormat % 1000000 !== 0) {
            text = "Số tiền không hợp lệ! Vui lòng làm tròn tới hàng trăm nghìn";
        }
        return text;
    }

    const searchHomeInsurances = () => {
        const data = {
            marketPrice: marketPrice.split(".").join(""),
            numberOfYearsUsed
        }
        dispatch(getInsuranceHomeProduct({data}));
        setIsSearch(true);
    }

    const viewDetailInsurance = (insuranceDetail) => {
        history.push(
            `${PATH}/detail/${insuranceDetail.productId}`,
            { 
                type: "home",
                data: {
                    totalAllSumAssured: marketPrice,
                    amount: (insuranceDetail.premium * (100 - insuranceDetail.discountRate || 0)) / 100,
                }
            }
        )
    };

    const buyInsurance = (insuranceDetail) => {
        history.push({
            pathname: `${PATH}/customer-info`,
            state: {
              insuranceDetail,
              type: 'home',
              logo: insuranceDetail.company.image,
              title: insuranceDetail.name,
              companyName: insuranceDetail.company.name,
              totalAllSumAssured: marketPrice,
              ownerShip, typeHome: homeInfo[typeHouse],
              numberOfYearsUsed,
              amount:
                (insuranceDetail.premium *
                  (100 - insuranceDetail.discountRate || 0)) /
                100,
              timeText: getStringDuration(
                insuranceDetail.billingType
              ).toLowerCase(),
            },
        })
    }

    useEffect(() => {
        if (gettingInsuranceHomeProduct) {
          ZaloPaySDK.showLoading();
        } else {
          ZaloPaySDK.hideLoading();
        }
    }, [gettingInsuranceHomeProduct]);

    useEffect(() => {
        if (getInsuranceHomeProductErrMsg) {
          ZaloPaySDK.showDialog({
            message: getInsuranceHomeProductErrMsg,
            buttons: [{ text: "Đóng" }],
          });
        }
    }, [getInsuranceHomeProductErrMsg]);

    return (
        <Fragment>
            <div style={{marginBottom: 36}} className="house-product">
                <div className="info-house-product">
                    <h5 className="font font-size-14">Chọn gói Bảo hiểm:</h5>
                    <div className="form-row">
                        <h6 className="font font-size-12">Loại nhà</h6>
                        <div className="row-house">
                            {Object.keys(LIST_HOME_TYPE).map((key, index) => {
                                return (
                                    <div key={index}
                                        className={[
                                            'col-house',
                                            typeHouse === key ? 'active-col-house' : '',
                                        ].join(' ')}
                                        onClick={() => onChangeTypeHouse(key)}    
                                    >
                                        <img src={ICON[key]} alt="" />
                                        <p className="font font-size-14">{LIST_HOME_TYPE[key]}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="form-row form-radio">
                        <p className="font font-size-12">Quyền sở hữu</p>
                        {
                            LIST_OWNERSHIP.map((m, i) => {
                                return <div className='input-radio' key={i}>
                                    <input
                                        id={m.value + "id" + i}
                                        type='checkbox'
                                        name={"ownership"}
                                        value={m.value}
                                        checked={ownerShip === m.value}
                                        onChange={handleChangeOwnerShip}
                                    />
                                    <label htmlFor={m.value + "id" + i}>{m.name}</label>
                                </div>
                            })
                        }
                    </div>
                    <div className="form-row">
                        <div className="title font font-size-12">Số năm đã sử dụng</div>
                        <div className="w-100">
                            <FloatInput
                                ref={refNumberOfYearsUsed}
                                label={setLabelNumberOfYearsUsed()}
                                type="number"
                                iconClear={true}
                                value={numberOfYearsUsed}
                                onChangeText={handleChangeNumberOfYearsUsed}
                                error={validNumberOfYearsUsed}
                                require
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="title font font-size-12">Giới hạn trách nhiệm (đ)</div>
                        <div className="w-100">
                            <FloatInput
                                ref={refMaketPrice}
                                label={setLabelMaketPrice()}
                                iconClear={true}
                                value={marketPrice}
                                onChangeText={handleChangePrice}
                                error={validMarketPrice}
                                require
                            />
                            <i style={{position: "relative", top: -15, fontSize: 12}}><strong>*Giới hạn trách nhiệm không vượt quá giá trị ngôi nhà</strong></i>
                        </div>
                    </div>
                </div>
                <div className="button-home-search">
                    <Button
                        onPress={() => searchHomeInsurances()}
                        className="search-insurance-button"
                        disabled={validMarketPrice || validNumberOfYearsUsed}
                        style={{
                            backgroundColor: (validMarketPrice || validNumberOfYearsUsed) ? "#dbdfe2" : "#004a9c",
                            cursor: (validMarketPrice || validNumberOfYearsUsed) ? "no-drop" : ""
                        }}
                    >
                        Tìm kiếm
                    </Button>
                </div>
            </div>
            {
                (insuranceHomeProduct && isSearch) && insuranceHomeProduct.map((insuranceDetail, index) => {
                    return <div className="card-product" key={index}>
                        <div className="swiper-slide-item box-shadow">
                            <div className="header-item">
                                <div className="header-logo">
                                    <img alt="" src={insuranceDetail.company.image} />
                                </div>
                                <div className="header-name">
                                    <div className="title color-black">
                                        {insuranceDetail.name}
                                    </div>
                                    <div className="name color-gray">
                                        {insuranceDetail.company.name}
                                    </div>
                                </div>
                            </div>
                            <div className="maximum-limit">
                                <div className="title color-black">Giới hạn trách nhiệm:</div>
                                <div className="value color-black">
                                    {marketPrice}đ
                                </div>
                            </div>
                            <div className="benefit">
                                <div className="title">Quyền lợi nổi bật</div>
                                <div className="list-benefit">
                                    {
                                        insuranceDetail.benefits.map((m, i) => {
                                            return <div className="benefit-item flex" key={i}>
                                                <div className="radio-active">
                                                    <img alt="" src={images.radio_active} />
                                                </div>
                                                <div className="content">{m.name}</div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div
                                    className="detail color-blue"
                                    onClick={() => viewDetailInsurance(insuranceDetail)}
                                >
                                    Xem chi tiết <img alt="" src={images.arrow_right} />
                                </div>
                            </div>
                            <div className="buy-insurance">
                                <div className="price">
                                    <span className="money color-black">
                                        {Util.formatMoneyVND(
                                            ((insuranceDetail.totalAllPremium || insuranceDetail.premium) *
                                            (100 - insuranceDetail.discountRate || 0)) /
                                            100
                                        )}
                                        đ
                                    </span>
                                    <span className="unit">
                                        {" "}
                                        /{billingTypeAdapter(insuranceDetail.billingType)}
                                    </span>
                                </div>
                                <Button
                                    className="buy-insurance-button"
                                    onPress={() => buyInsurance(insuranceDetail)}
                                    style={{ backgroundColor: colors.main }}
                                >
                                    Chọn mua
                                </Button>
                            </div>
                        </div>
                    </div>
                })
            }
        </Fragment>
    )
};

export default React.memo(HouseProduct);
