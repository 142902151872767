import { types } from './carInfomation.type';

export const getCarBrandsAndModelsTasco = () => {
  return {
    type: types.GETTING_CAR_BRANDS_MODELS_TASCO,
  }
};

export const getCarBrands = () => {
  return {
    type: types.GETTING_CAR_MIC_BRANDS,
  }
};

export const getCarModels = ({ brand }, callback) => {
  return {
    type: types.GETTING_CAR_MIC_MODELS,
    brand,
    callback
  }
};

export const clearCarBrand = () => {
  return {
    type: types.CLEAR_CAR_BRANDS
  }
};
