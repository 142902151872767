export const types = {
    GETTING_CAR_BRANDS_MODELS_TASCO: 'GETTING_CAR_BRANDS_MODELS_TASCO',
    GETTING_CAR_MIC_BRANDS: 'GETTING_CAR_MIC_BRANDS',
    GETTING_CAR_MIC_MODELS: 'GETTING_CAR_MIC_MODELS',
    GET_CAR_BRANDS_SUCCESS: 'GET_CAR_BRANDS_SUCCESS',
    GET_CAR_MODELS_SUCCESS: 'GET_CAR_MODELS_SUCCESS',
    GET_CAR_BRANDS_FAIL: 'GET_CAR_BRANDS_FAIL',
    GET_CAR_MODELS_FAIL: 'GET_CAR_MODELS_FAIL',
    CLEAR_CAR_BRANDS: 'CLEAR_CAR_BRANDS',
  }
  