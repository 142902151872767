import React, { useEffect } from "react";
import { SafeAreaView } from "../../components/SafeAreaView";
import images from "../../utils/images";
import "./QAScreen.scss";
import { useHistory, useLocation } from "react-router-dom";
import ZaloPaySDK from "../../utils/zalopay";
import { ABIC_PRODUCT_CODES, ABIC_PRODUCT_CODES_HOME, ABIC_PRODUCT_CODES_MOTO_CAR, BSH_PRODUCT_CODES, HDI_PRODUCT_CODES, PATH, UIC_PRODUCT_CODES, VNI_PRODUCT_CODES } from "../../utils/const";

const QAScreen = () => {
  const history = useHistory();
  const {
    productCode
  } = useLocation().state;

  let QAData = [];
  // if (UIC_PRODUCT_CODES.includes(productCode)) {
  //   QAData = require('./QAData/uic.json');
  // } else if (VNI_PRODUCT_CODES.includes(productCode)) {
  //   QAData = require('./QAData/vni.json');
  // } else if (HDI_PRODUCT_CODES.includes(productCode)) {
  //   QAData = require('./QAData/hdi.json');
  // } else if (ABIC_PRODUCT_CODES.includes(productCode)) {
  //   QAData = require('./QAData/abic.json')
  // } else if (BSH_PRODUCT_CODES.includes(productCode)) {
  //   QAData = require('./QAData/bsh.json')
  // } else {
  //   QAData = require('./QAData/pti.json');
  // }
  if (UIC_PRODUCT_CODES.includes(productCode)) {
    QAData = require('./QAData/uic.json');
  } else if([...VNI_PRODUCT_CODES, ...HDI_PRODUCT_CODES, ...ABIC_PRODUCT_CODES_MOTO_CAR, ...BSH_PRODUCT_CODES].includes(productCode)) {
    QAData = require('./QAData/for_all_company.json');
  } else if(ABIC_PRODUCT_CODES_HOME.includes(productCode)) {
    QAData = require('./QAData/abic-home.json');
  } else {
    QAData = require('./QAData/pti.json');
  }

  useEffect(() => {
    ZaloPaySDK.setTitle("Câu hỏi thường gặp");
  }, []);

  const gotoDetail = (item) => {
    history.push(`${PATH}/qa-detail`, { QADetail: item });
  };
  return (
    <SafeAreaView>
      <div className="margin-16 qa-screen">
        {QAData.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => gotoDetail(item)}
              className="qa-item bg-white flex-v-center flex-space-between"
            >
              <div className="qa-title color-black font-size-13">{item.section}</div>
              <div className="qa-icon">
                <img
                  src={images.icon_systems_other_arrow_right}
                  alt="icon_systems"
                />
              </div>
            </div>
          );
        })}
      </div>
    </SafeAreaView>
  );
};

export default QAScreen;
