import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import rootSaga from './rootSaga';
import { NODE_ENV } from "../utils/const";
import { appParamsReducer as appParams } from './appparams/appparams.reducer';
import { insuranceReducer as insurance } from './insurance/insurance.reducer';
import { customerReducer as customer } from './customer/customer.reducer';
import { paymentReducer as payment } from './payment/payment.reducer';
import { contractReducer as contract } from './contract/contract.reducer';
import { addressReducer as address } from './address/address.reducer';
import { carInfomationReducer as carInfomation } from './carInfomation/carInfomation.reducer';
import { agreementpayReducer as agreementpay } from './agreementpay/agreementpay.reducer';
import { companiesReducer as company } from './companies/company.reducer';

/* Reducers */
const reducers = {
  appParams,
  insurance,
  customer,
  payment,
  contract,
  address,
  agreementpay,
  carInfomation,
  company,
};

/* Redux-Persist */
const rootReducer = persistCombineReducers({
  key: 'root',
  storage,
  whitelist: ['appParams', 'customer'],
}, reducers);

/* Saga */
const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;

// If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
/* istanbul ignore next */
if (process.env.NODE_ENV !== NODE_ENV.PRODUCTION && typeof window === 'object') {
  /* eslint-disable no-underscore-dangle */
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
}

/* Create Store */
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);
/* Redux-Persist + Store */
export const persistor = persistStore(store);

/* Run saga */
sagaMiddleware.run(rootSaga);
